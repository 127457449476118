import axios from 'axios'
import { cognitoToken } from '.'
import config from '../config/config'

export const np6ApiURL = config.np6ApiURL

let client = axios.create()
client.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${cognitoToken}`
  return config
})
client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export const getAccounts = (): Promise<string[]> => {
  return client.get<string[]>(`${np6ApiURL}/accounts`).then(response => response.data)
}

export interface table {
  id: number
  name: string
  creationDate: string
}

export const getTables = (account: string): Promise<table[]> => {
  return client.get<table[]>(`${np6ApiURL}/accounts/${account}/data/tables`).then(response => response.data)
}

export const getTableData = (account: string, tableId: number): Promise<any[]> => {
  return client
    .get<any[]>(`${np6ApiURL}/accounts/${account}/data/tables/${tableId}/records`)
    .then(response => response.data)
}
