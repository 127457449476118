import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Save from '@material-ui/icons/Save'
import { Field, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { client, fetchShops, getShopReference, toSanityMerch } from '../api/sanityClient'
import { merch } from '../model/merch'
import { shop } from '../model/shop'
import ButtonGeneric from './ButtonGeneric'
import { KeyboardDateTimePicker } from './formikFormComponents/KeyboardDateTimePicker'

function updateSanityMerch(values: merch): Promise<any> {
  if (!values.id) {
    return client.create(toSanityMerch(values))
  }

  return client
    .patch(values.id)
    .set({
      code: values.code,
      startAt: values.startAt.toISOString(),
      endAt: values.endAt.toISOString(),
      'label._type': 'translatedString',
      'label.fr': values.label.fr,
      shops: values.shops.map(getShopReference),
    })
    .commit()
}

const MerchEditForm = (props: { merch?: merch; onSuccess?: () => void; onFail?: () => void }) => {
  const [shops, setShops] = useState<shop[]>([])
  const [isNewMerch] = useState(!props.merch?.id)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    fetchShops()
      .then(setShops)
      .catch(error => {
        console.error(error)
        toast.error('Failed to retrive shops, please see console for error')
      })
  }, [])

  const initialValues = props.merch || {
    id: '',
    code: '',
    label: {},
    previewUrls: {},
    startAt: new Date(),
    endAt: new Date(),
    shops: [],
  }
  const validationSchema = Yup.object({
    code: Yup.string().required('Required'),
    startAt: Yup.date().required('Required'),
    endAt: Yup.date().min(Yup.ref('startAt'), 'End date must be after start date').required('Required'),
  })

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        <h2>{isNewMerch ? 'New' : 'Edit'} merchandising</h2>
      </Grid>
      <Grid item>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setIsSubmitting(true)
            updateSanityMerch(values)
              .then(() => {
                setSubmitting(false)
                setIsSubmitting(false)
                if (props.onSuccess) props.onSuccess()
              })
              .catch(error => {
                console.error(error)
                if (props.onFail) props.onFail()
              })
          }}
        >
          <Form>
            <Grid container>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="id"
                    label="id"
                    variant="filled"
                    size="small"
                    className="fullWidth"
                    disabled
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Field
                    name="code"
                    label="code"
                    variant="filled"
                    size="small"
                    className="fullWidth"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="label.fr"
                    label="label (français)"
                    variant="filled"
                    size="small"
                    className="fullWidth"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="startAt"
                    name="startAt"
                    className="fullWidth"
                    inputVariant={'filled'}
                    format="dd/MM/yyyy HH:mm:ss"
                    size="small"
                    component={KeyboardDateTimePicker}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="endAt"
                    name="endAt"
                    className="fullWidth"
                    inputVariant={'filled'}
                    format="dd/MM/yyyy HH:mm:ss"
                    size="small"
                    component={KeyboardDateTimePicker}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl className="fullWidth" size="small" variant="filled">
                    <InputLabel htmlFor="shops">shop</InputLabel>
                    <Field component={Select} multiple name="shops" inputProps={{ id: 'shops' }}>
                      {shops.map(shop => (
                        <MenuItem key={shop.id} value={shop.id}>
                          {shop.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid container item xs={6} justify={'flex-end'}>
                  <ButtonGeneric
                    label={isNewMerch ? 'Save new merch' : 'Save changes'}
                    isDisabled={isSubmitting}
                    startIcon={<Save />}
                    type="submit"
                    color="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  )
}

export default MerchEditForm
