import axios from 'axios'
import { Patcher } from '.'
import config from '../config/config'

export var cognitoToken = ''

export function setCognitoToken(token: string) {
  cognitoToken = token
}

const ENDPOINTS = {
  sales: {
    suffix: '/Sale',
  },
  propertyReferences: {
    suffix: '/PropertyReference',
  },
  place: {
    suffix: '/Place',
  },
}

type IFetchProps = {
  endpoint: string
  ids?: string[]
  query?: Map<string, string>
}

export const imStrongUrl = config.imStrongURL
export const touropUrl = config.touropApiURL
export const touropDbUrl = `${touropUrl}/db/tourop`
export const lambdaInvokerUrl = `${touropUrl}/invoke`

let client = axios.create()
client.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${cognitoToken}`
  return config
})
client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export interface dbRef {
  $id: any
  $ref: string
}

export interface touropShop {
  _id: string
  name: string
  brand: dbRef
  rootUrl: string
  locales: string[]
  sellingCountryCode: string
}

export const toBrandShop = (shop: touropShop): string[] => {
  const brand = shop._id.substring(0, 2)
  return shop.locales.map(
    locale => `${brand.toUpperCase()} ${locale.toLowerCase()}-${shop.sellingCountryCode.toUpperCase()}`
  )
}

export const getShop = (shopID: string): Promise<touropShop> => {
  return client.get<touropShop>(`${touropDbUrl}/Shop/${shopID}`).then(response => response.data)
}

export const getShops = (): Promise<touropShop[]> => {
  return client.get<touropShop[]>(`${touropDbUrl}/Shop?sort=_id`).then(response => response.data)
}

export const invokeLambda = (payload: any, lambdaName: string): Promise<invokeLambdaResponse> => {
  return client
    .post<invokeLambdaResponse>(`${lambdaInvokerUrl}/${encodeURI(lambdaName)}`, {
      payload: payload,
    })
    .then(response => response.data)
}

export interface invokeLambdaResponse {
  status: 'SUCCESS' | 'ERROR'
  error: string
  message: string
}

export interface lambdaProductListBuilderPayload {
  brandCode?: string
  propertyReferenceID?: string
  scope?: string
  merchCode?: string
}
export const lambdaProductListBuilderName = 'marketing-services-{env}-productListBuilder'
export const lambdaSanityToS3ProductName = 'marketing-services-{env}-sanityToFrontS3'

export interface lambdaVpvProductPayload {
  brandCode?: string
  merchCode?: string
  force: boolean
}
export const lambdaVpvProductName = 'vpvProduct-{env}-tsf-runner'

const fetch = async <T>(props: IFetchProps) => {
  const { endpoint, ids, query } = props
  const params = new URLSearchParams('sort=_id')

  if (ids && ids.length > 0) {
    params.append('_id.$in', ids.join(','))
  }
  if (query) {
    query.forEach((value, key) => params.append(key, value))
  }
  let res = await client.get<T>(`${touropDbUrl}${endpoint}`, {
    params: params,
  })
  return res.data
}

interface IPatchProps<T> {
  endpoint: string
  id: number
  patch: Patcher<T>
}

const patch = async <T>(props: IPatchProps<T>) => {
  const { endpoint, id, patch } = props
  const patchSet = patch.getOperation()
  let resp: T

  await client
    .patch<T>(`${touropDbUrl}${endpoint}/${id}`, patchSet)
    .then(response => {
      resp = response.data
    })
    .catch(error => console.log(error.message))
  return resp!
}

export { fetch, patch, ENDPOINTS }
