import Amplify, { Auth } from 'aws-amplify'
import axios from 'axios'
import React, { useState } from 'react'
import config from '../config/config'
import { createCtx } from './createContext'
import { getUsersInGroup } from '../api/awsClient'
import { ADMIN_GROUP_NAME, mappingCognitoUserToUser } from '../model/user'
import { toast } from 'react-toastify'

Amplify.configure({
  Auth: {
    region: config.awsRegion,
    identityPoolRegion: config.awsRegion,
    userPoolId: config.cognitoUserPoolID,
    userPoolWebClientId: config.cognitoUserPoolWebClientID,
    oauth: {
      domain: config.oAuthDomain,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: config.oAuthRedirectSignIn,
      redirectSignOut: config.oAuthRedirectSignOut,
      responseType: 'token',
    },
  },
})

interface authDataInterface {
  userEmail: string
  cognitoToken: string
  sanityToken: string
  isAdmin?: boolean
}

type AuthContextType = {
  authData: authDataInterface
  updateUserInfo: () => void
  logout: () => void
}

const [useCtx, CtxProvider] = createCtx<AuthContextType>()

type Props = {
  children: React.ReactNode
}
export const AuthProvider = ({ children }: Props) => {
  const [authData, setAuthData] = useState<authDataInterface>({
    userEmail: '',
    cognitoToken: '',
    sanityToken: '',
    isAdmin: false,
  })

  const logout = () => {
    Auth.signOut()
    setAuthData({
      userEmail: '',
      cognitoToken: '',
      sanityToken: '',
      isAdmin: false,
    })
  }
  const updateUserInfo = async () => {
    const userInfo = await getCognitoUserInfo()
    if (!userInfo || !userInfo.idToken) {
      loginCognito()
      return
    }
    const sanityToken = await loginSanity(userInfo.idToken)
    if (!sanityToken) {
      console.error('sanity authentification failed')
      return
    }
    getUsersInGroup(ADMIN_GROUP_NAME, (err, data) => {
      if (err) {
        console.error(err)
        toast.error('Error during fetching user role, please see console for error')
        setAuthData({
          userEmail: userInfo.email,
          cognitoToken: userInfo.idToken,
          sanityToken: sanityToken,
          isAdmin: false,
        })
      } else {
        const listAdmin = mappingCognitoUserToUser(data?.Users)?.map((u: { email: any }) => u?.email)
        setAuthData({
          userEmail: userInfo.email,
          cognitoToken: userInfo.idToken,
          sanityToken: sanityToken,
          isAdmin: listAdmin?.includes(userInfo?.email),
        })
      }
    })
  }

  const getCognitoUserInfo = () => {
    return Auth.currentAuthenticatedUser()
      .then(user => {
        return {
          email: user.attributes.email,
          idToken: user.signInUserSession?.idToken?.jwtToken || '',
        }
      })
      .catch(error => {
        console.error('user is not connected')
        return undefined
      })
  }
  const loginCognito = () => Auth.federatedSignIn()

  const loginSanity = (cognitoIdToken: any) => {
    return axios
      .get<{ sanitySessionToken: string }>(config.sanityGenerateTokenURL, {
        headers: {
          Authorization: `Bearer ${cognitoIdToken}`,
        },
      })
      .then(response => response.data.sanitySessionToken)
      .catch(error => {
        console.error(error)
        return ''
      })
  }

  return (
    <CtxProvider value={{ authData: authData, updateUserInfo: updateUserInfo, logout: logout }}>{children}</CtxProvider>
  )
}

export const useAuth = useCtx
