import { Grid, Paper, Box } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { useState } from 'react'
import { toast } from 'react-toastify'
import ButtonGeneric from './ButtonGeneric'
import MerchEditFormDialog from './MerchEditFormDialog'
import MerchSearchForm, { merchSearchFormValues } from './MerchSearchForm'
import MerchSearchTable from './MerchSearchTable'
import { merch } from '../model/merch'

const MerchSearch = () => {
  const [merchFormValues, setMerchFormValues] = useState<merchSearchFormValues>()

  const onSubmit = async (values: merchSearchFormValues): Promise<void> => {
    setMerchFormValues(values)
  }

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid container item justifyContent={'flex-end'}>
        <AddMerchButton />
      </Grid>
      <Grid item>
        <Paper>
          <Box p={2}>
            <MerchSearchForm onSubmit={onSubmit} setMerchFormValues={setMerchFormValues} />
          </Box>
        </Paper>
      </Grid>
      <Grid item>
        <MerchSearchTable searchValues={merchFormValues} />
      </Grid>
    </Grid>
  )
}

const AddMerchButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const startDate = new Date()
  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)

  const endDate = new Date()
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setSeconds(59)

  const m: merch = {
    id: '',
    code: '',
    startAt: startDate,
    endAt: endDate,
    label: {},
    shops: [],
    previewUrls: {},
  }
  return (
    <div>
      <ButtonGeneric label={'New merchandising'} startIcon={<Add />} onClick={() => setIsDialogOpen(true)} />
      <MerchEditFormDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        onSuccess={() => toast.success('Merch created')}
        merchValue={m}
        onFail={() => toast.error('Error during merch creation, please see console for error')}
      />
    </div>
  )
}

export default MerchSearch
