import SanityClientConstructor from '@sanity/client'
import { v4 as uuidv4 } from 'uuid'
import config from '../config/config'
import { merch } from '../model/merch'
import { shop } from '../model/shop'

export const sanityStudioURL = config.sanityStudioURL
export const pushNotificationURL = `${sanityStudioURL}/desk/pushNotifications`
export const landingPageURL = `${sanityStudioURL}/desk/landingPage`
export const merchandisingURL = `${sanityStudioURL}/desk/merchandising`

const newClient = (token: string) => {
  return new SanityClientConstructor({
    projectId: config.sanityProjectID,
    dataset: config.sanityDataset,
    ignoreBrowserTokenWarning: true, // we get a generate a token from an endpoint protected by cognito
    token: token,
    useCdn: false,
  })
}

export let client = newClient('')

export const initClient = (token: string) => {
  client = newClient(token)
}

export const fetchNumberOfResults = async (sanityQuery: string): Promise<number> => {
  try {
    return await client.fetch<number>(`count(${sanityQuery})`)
  } catch (error) {
    console.error(error)
    return -1
  }
}

export const fetchPageData = async <T>(pageNumber: number, pageSize: number, sanityQuery: string): Promise<T[]> => {
  try {
    const from = pageNumber * pageSize
    const to = from + pageSize - 1
    const response = await client.fetch<T[]>(`${sanityQuery}[${from}..${to}]`)
    return response
  } catch (error) {
    throw error
  }
}

export interface sanityMerch {
  _type: string
  _id?: string
  code: string
  shops: {
    _ref: string
  }[]
  label: { [key: string]: string }
  previewUrls: { [key: string]: string }
  endAt: string
  startAt: string
  displayOptions?: {
    position?: number
    carouselIndex?: number
    fallBackPosition?: number
  }
}

export const fromSanityMerch = (sMerch: sanityMerch): merch => {
  return {
    ...sMerch,
    id: sMerch._id || '',
    endAt: new Date(sMerch.endAt),
    startAt: new Date(sMerch.startAt),
    shops: sMerch.shops.map(shop => shop._ref.split('.')[1] || ''),
    displayOptions: sMerch.displayOptions,
  }
}

export const toSanityMerch = (merch: merch): sanityMerch => {
  const newMerch = {
    ...merch,
    _type: 'merchandising',
    _id: merch.id ? merch.id : `merchandising.${uuidv4()}`,
    code: merch.code,
    label: { _type: 'translatedString', ...merch.label },
    previewUrls: merch.previewUrls,
    endAt: merch.endAt.toISOString(),
    startAt: merch.startAt.toISOString(),
    shops: merch.shops.map(getShopReference),
  }

  return newMerch
}

export interface sanityShop {
  _id: string
  brand: {
    _ref: string
  }
  name: string
}

export const fromSanityShop = (sShop: sanityShop): shop => {
  const idSplit = (sShop._id || '').split('.')
  const cleanID = idSplit.length === 2 ? idSplit[1] : sShop._id
  return {
    id: cleanID,
    name: sShop.name || cleanID,
  }
}

export const fetchShops = (): Promise<shop[]> => {
  return client
    .fetch<sanityShop[]>(`*[_type == "shop" && !(_id in path("drafts.**"))]`)
    .then(sanityShops => {
      const newShops = sanityShops.map(fromSanityShop).sort((a, b) => a.name.localeCompare(b.name))
      newShops.push({ id: 'AFHOFRFR', name: 'Airfrance Holidays FR' })
      return newShops.sort((a, b) => a.name.localeCompare(b.name))
    })
    .catch(error => {
      throw error
    })
}

export interface sanityReference {
  _ref: string
  _key: string
  _type: 'reference'
}

export const getShopReference = (shop4CharID: string): sanityReference => {
  return {
    _ref: `shop.${shop4CharID}`,
    _key: uuidv4(),
    _type: 'reference',
  }
}
