import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteForever from '@material-ui/icons/DeleteForever'
import FileCopy from '@material-ui/icons/FileCopy'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client, fetchNumberOfResults, fetchPageData, fromSanityMerch, sanityMerch } from '../api/sanityClient'
import { locales } from '../model/locales'
import { merch } from '../model/merch'
import ButtonGeneric from './ButtonGeneric'
import ConfirmDialog from './ConfirmDialog'
import MerchEditFormDialog from './MerchEditFormDialog'
import { merchSearchFormValues } from './MerchSearchForm'

const convertSanityMerchs = (sMerchs: sanityMerch[] | null): merch[] => {
  if (!sMerchs) {
    return []
  }
  return sMerchs.map(fromSanityMerch)
}

const getSanityFilter = (searchValues?: merchSearchFormValues): string => {
  const codeFilter = searchValues?.code ? ` && code match "*${searchValues.code}*"` : ''
  const shopFilter = searchValues?.shop ? ` && shops[]._ref match "*.${searchValues.shop}"` : ''
  const brandFilter = searchValues?.brand ? ` && shops[]._ref match "*.${searchValues.brand}*"` : ''
  const labelFilter = searchValues?.name
    ? ` && (${locales.map(locale => `label.${locale} match "*${searchValues.name}*"`).join(' || ')})`
    : ''
  const endAtFilter = searchValues?.ongoingAt
    ? ` && endAt >= '${new Date(searchValues.ongoingAt).toISOString()}' && startAt <= '${new Date(
        searchValues.ongoingAt
      ).toISOString()}'`
    : ''
  return `*[_type=="merchandising"${codeFilter}${shopFilter}${brandFilter}${labelFilter}${endAtFilter}  && !(_id in path("drafts.**"))] | order(startAt asc)`
}

const useStyles = makeStyles({
  expired: {
    backgroundColor: '#eaeaea',
  },
  active: {
    backgroundColor: 'lightgoldenrodyellow',
  },
  future: {
    backgroundColor: 'lightsteelblue',
  },
})

const MerchSearchTable = (props: { searchValues?: merchSearchFormValues }) => {
  const [merchs, setMerchs] = useState<merch[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [numberOfResult, setNumberOfResult] = useState(-1)

  // new filter or page size change => reset current page number
  useEffect(() => {
    setCurrentPage(0)
  }, [props.searchValues, pageSize])

  // on change (filter, page, pageSize) => fetch results and total number of results
  useEffect(() => {
    ;(async () => {
      try {
        const filter = getSanityFilter(props.searchValues)
        const merchsResults = await fetchPageData<sanityMerch>(currentPage, pageSize, filter)
        const merchsTotalcount = await fetchNumberOfResults(filter)
        setMerchs(convertSanityMerchs(merchsResults))
        setNumberOfResult(merchsTotalcount)
      } catch (error) {
        console.error(error)
        toast.error('Error during merch search, please see console for error')
      }
      return
    })()
  }, [currentPage, pageSize, props.searchValues])

  const tableHeader = (
    <TableHead>
      <TableRow>
        {['Code', 'Name', 'Shops', 'Start time', 'End time', 'Pos', 'Car', 'FBack', 'Actions'].map(
          (tableHeader, index) => (
            <TableCell align={index ? 'right' : 'left'} key={index}>
              {tableHeader}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )

  const getMerchNameTableContent = (merch: merch): string => {
    if (merch.label) {
      const translatedLocales = locales.filter(locale => merch.label[locale])
      if (translatedLocales.length) {
        const locale = translatedLocales[0]
        return `(${locale}) ${merch.label[locale]}`
      }
    }
    return ''
  }
  const classes = useStyles()
  const tableBody = (
    <TableBody>
      {merchs.map((merch, index) => (
        <TableRow
          key={merch.id}
          className={
            merch.endAt && merch.endAt < new Date()
              ? classes.expired
              : merch.startAt && merch.startAt < new Date()
              ? classes.active
              : classes.future
          }
        >
          <TableCell>
            <Link to={`/merch/${merch.id}`}>{merch.code}</Link>
          </TableCell>
          <TableCell align={'right'}>{getMerchNameTableContent(merch)}</TableCell>
          <TableCell align={'right'}>{merch.shops.join(', ')}</TableCell>
          <TableCell align={'right'}>{merch.startAt.toLocaleString('fr-FR')}</TableCell>
          <TableCell align={'right'}>{merch.endAt.toLocaleString('fr-FR')}</TableCell>
          <TableCell align={'right'}>{merch.displayOptions?.position}</TableCell>
          <TableCell align={'right'}>{merch.displayOptions?.carouselIndex}</TableCell>
          <TableCell align={'right'}>{merch.displayOptions?.fallBackPosition}</TableCell>
          <TableCell align={'right'}>
            <ButtonGroup>
              <DuplicateMerchButton merchValue={merch} />
              <DeleteMerchButton
                merchValue={merch}
                onSuccess={() => setMerchs(merchs.filter(m => m.id !== merch.id))}
              />
            </ButtonGroup>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
  const tableFooter = (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={numberOfResult}
          page={currentPage}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={(event: any) => setPageSize(event.target.value)}
          onPageChange={(event: any, page: number) => setCurrentPage(page)}
        />
      </TableRow>
    </TableFooter>
  )
  return (
    <Paper>
      <Box p={2}>
        <TableContainer>
          <Table size="small">
            {tableHeader}
            {tableBody}
            {tableFooter}
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}

const DeleteMerchButton = (props: { merchValue: merch; onSuccess?: () => void }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  return (
    <div>
      <ButtonGeneric startIcon={<DeleteForever />} size="small" isDanger={true} onClick={() => setIsDialogOpen(true)} />
      <ConfirmDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        onConfirm={() =>
          client
            .delete(props.merchValue?.id)
            .then(() => {
              toast.success('Merch deleted')
              if (props.onSuccess) props.onSuccess()
            })
            .catch(error => {
              console.error(error)
              toast.error('Error during merch delete, please see console for error')
            })
        }
        isDanger={true}
        title={'Delete Merch ?'}
        content={`Delete merch ${props.merchValue?.code} (${props.merchValue?.id})`}
      />
    </div>
  )
}

const DuplicateMerchButton = (props: { merchValue: merch }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  return (
    <div>
      <ButtonGeneric startIcon={<FileCopy />} size="small" onClick={() => setIsDialogOpen(true)} />
      <MerchEditFormDialog
        open={isDialogOpen}
        merchValue={{ ...props.merchValue, id: '' }}
        handleClose={() => setIsDialogOpen(false)}
        onSuccess={() => toast.success('Merch created')}
        onFail={() => toast.error('Error during merch creation, please see console for error')}
      />
    </div>
  )
}

export default MerchSearchTable
