import axios from 'axios'
import { cognitoToken } from '.'
import config from '../config/config'

export const marketingApiURL = config.marketingApiURL

let client = axios.create()
client.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${cognitoToken}`
  return config
})
client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export interface DatalayerEvent {
  day: string
  datalayer: {
    event: string
    brand: string
    shop: string
    quote: {
      connectivitySessionId: string
    }
    user: {
      user_biid: string
      session_biid: string
    }
    page: {
      pageTitle: string
    }
  }
}

export const datalayer = (date: string, filter: string): Promise<DatalayerEvent[]> => {
  return client
    .get<DatalayerEvent[]>(`${marketingApiURL}/admin/datalayer`, {
      params: {
        date: date,
        filter: filter,
      },
    })
    .then(response => response.data)
}

export interface ConnectivityEvent {
  day: string
  level: string
  message: string
  data: {
    error: string
  }
}

export const connectivity = (date: string, filter: string): Promise<ConnectivityEvent[]> => {
  return client
    .get<ConnectivityEvent[]>(`${marketingApiURL}/admin/connectivity`, {
      params: {
        date: date,
        filter: filter,
      },
    })
    .then(response => response.data)
}
