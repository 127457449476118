const live = {
  // Backend
  imStrongURL: 'https://touroperating.live.perfectstay.io',
  touropApiURL: 'https://tourop-api.live.perfectstay.io',
  marketingApiURL: 'https://marketing-api.live.perfectstay.io',
  np6ApiURL: 'https://np6-api.live.perfectstay.io',
  promoCodesApiURL: 'https://promocodes.live.perfectstay.io',
  authenticatorApiURL: 'https://authenticator.live.perfectstay.io',
  sanityGenerateTokenURL: 'https://sanity-login.live.perfectstay.io/generateToken',
  creditApiURL: 'https://credit-api.marketing.live.perfectstay.io',

  // Sanity
  sanityStudioURL: 'https://sanity-marketing.live.perfectstay.io',
  sanityProjectID: 'ox7azn9e',
  sanityDataset: 'live',

  // Cognito
  cognitoUserPoolID: 'eu-west-1_M9oM91BmN',
  cognitoUserPoolWebClientID: '65cgb2j677tqclfm3jv85vfs3q',

  // AWS
  awsRegion: 'eu-west-1',

  // OAuth
  oAuthDomain: 'login.perfectstay.com',
  oAuthRedirectSignIn: 'https://marketing.live.perfectstay.io/login',
  oAuthRedirectSignOut: 'https://marketing.live.perfectstay.io/logout',

  airtableRankingURL: 'https://airtable.com/appz0OGyIna1kcylv/tblVvKzQ4Y2lkgDm1/viwFBGfHpdUJQTYaz?blocks=hide',

  realmAppId: 'marketing-dljwt',
  realmApiKey: '1xOA7qsqWkOHNxcaxSeMtEMurpUzVyIgP7lzNiJEiAcyHOsQai6fztmx3isxsGKt',

  accessKeyId: 'AKIAQQ2YF4N47YWE4DMO',
  secretAccessKey: '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',
}

export default live
