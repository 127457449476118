import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { fetchShops } from '../api/sanityClient'
import { shop } from '../model/shop'
import ButtonSearch from './ButtonSearch'
import { KeyboardDateTimePicker } from '../components/formikFormComponents/KeyboardDateTimePicker'
import { IconButton, Tooltip } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { isEqual } from 'lodash'

export interface merchSearchFormValues {
  code?: string
  name?: string
  shop?: string
  brand?: string
  ongoingAt?: string
  includeArchive?: boolean
}

const MerchPageForm = (props: {
  onSubmit: (values: merchSearchFormValues) => Promise<void>
  setMerchFormValues: (values: merchSearchFormValues) => void
}) => {
  const [shops, setShops] = useState<shop[]>([])
  const [brands, setBrands] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialValues: merchSearchFormValues = {
    code: '',
    name: '',
    shop: '',
    brand: '',
    includeArchive: false,
  }
  useEffect(() => {
    fetchShops()
      .then(sanityShops => {
        setShops(sanityShops)
        const availableBrands = sanityShops
          .map(shop => shop.id)
          .filter(id => id.length === 4)
          .map(id => id.substring(0, 2))
          .sort((a, b) => a.localeCompare(b))
        setBrands(Array.from(new Set(availableBrands)))
      })
      .catch(error => {
        console.error(error)
        toast.error('Failed to retrive shops, please see console for error')
      })
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setIsSubmitting(true)
        await props.onSubmit(values)
        setSubmitting(false)
        setIsSubmitting(false)
      }}
    >
      {({ values = {}, resetForm }) => {
        console.log('values', values)
        return (
          <Form>
            <Grid container justify={'center'}>
              <Grid item container spacing={2}>
                <Grid item xs={2}>
                  <Field
                    label="Ongoing"
                    name="ongoingAt"
                    className="fullWidth"
                    format="dd/MM/yyyy HH:mm:ss"
                    inputVariant="outlined"
                    component={KeyboardDateTimePicker}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="code"
                    label="Code"
                    variant="outlined"
                    size="small"
                    className="fullWidth"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="name"
                    label="Name"
                    variant="outlined"
                    size="small"
                    className="fullWidth"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl className="formik-select--outlined fullWidth" variant="outlined">
                    <InputLabel htmlFor="shop">Shop</InputLabel>
                    <Field component={Select} name="shop" inputProps={{ id: 'shop' }} variant="outlined">
                      <MenuItem value={''}>None</MenuItem>
                      {shops.map(shop => (
                        <MenuItem key={shop.id} value={shop.id}>
                          {shop.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className="formik-select--outlined fullWidth" variant="outlined">
                    <InputLabel htmlFor="brand">Brand</InputLabel>
                    <Field component={Select} name="brand" inputProps={{ id: 'brand' }} variant="outlined">
                      <MenuItem value={''}>None</MenuItem>
                      {brands.map(brand => (
                        <MenuItem key={brand} value={brand}>
                          {brand}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid container item xs={6} justify={'flex-end'}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="includeArchive"
                    size="small"
                    disabled
                    Label={{ label: 'Include archives' }}
                  />
                  <ButtonSearch isDisabled={isSubmitting} />
                  {!isEqual(values, initialValues) && (
                    <Tooltip
                      title="Clear search"
                      aria-label="Clear search"
                      onClick={() => {
                        props.setMerchFormValues({} as merchSearchFormValues)
                        resetForm()
                      }}
                    >
                      <IconButton aria-label="clear" style={{ marginLeft: '10px' }}>
                        <Close fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default MerchPageForm
