import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ButtonGeneric from './ButtonGeneric'

const ConfirmDialog = (props: {
  open: boolean
  handleClose: () => void
  title?: string
  content: string
  isDanger?: boolean
  confirmLabel?: string
  cancelLabel?: string
  onConfirm: () => void
}) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth={'sm'}>
      {props.title ? <DialogTitle>{props.title}</DialogTitle> : ''}
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <ButtonGeneric
          label={props.confirmLabel || 'Confirm'}
          isDanger={props.isDanger}
          onClick={() => {
            props.onConfirm()
            props.handleClose()
          }}
        />
        <ButtonGeneric
          label={props.cancelLabel || 'Cancel'}
          onClick={() => {
            props.handleClose()
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
