import { useContext } from 'react'
import { TableCell, TableSortLabel, Box } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { slugify } from '../../../utils/helpers'
import { DatagridColumn, DatagridContext } from './DatagridModel'

const TableSortCell = <T,>({ label, name }: DatagridColumn<T>) => {
  const { searchCriteria, onSort } = useContext(DatagridContext)
  const { sortBy, orderBy = 'asc' } = searchCriteria
  return (
    <TableCell key={slugify(label)} sortDirection={orderBy}>
      <TableSortLabel
        active={sortBy === name}
        direction={orderBy}
        onClick={() => onSort(name, orderBy === 'asc' ? 'desc' : 'asc')}
      >
        {label}
        {sortBy === name ? (
          <Box component="span" sx={visuallyHidden}>
            {orderBy === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  )
}

export default TableSortCell
