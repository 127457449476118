import { Grid, Paper, Box, Link, Typography, CircularProgress } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { client, fromSanityMerch, sanityMerch, merchandisingURL } from '../api/sanityClient'
import {
  getShop,
  touropShop,
  toBrandShop,
  imStrongUrl,
  lambdaProductListBuilderPayload,
  lambdaProductListBuilderName,
  invokeLambda,
  lambdaVpvProductPayload,
  lambdaVpvProductName,
} from '../api/backendClient'
import { merch as merchModel } from '../model/merch'
import MerchEditForm from './MerchEditForm'
import { toast } from 'react-toastify'
import ButtonGeneric from './ButtonGeneric'
import Sync from '@material-ui/icons/Sync'

const MerchEdit = () => {
  const [merch, setMerch] = useState<merchModel>()
  const [shops, setShops] = useState<touropShop[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [urlParameters] = useState<{ merchSanityID?: string }>(useParams())

  useEffect(() => {
    ;(async () => {
      try {
        const merchDocument = await client.getDocument<sanityMerch>(urlParameters.merchSanityID || '')
        if (merchDocument) {
          const merchObject = fromSanityMerch(merchDocument)
          setMerch(merchObject)
          const touropShops = await Promise.all(merchObject.shops.map(getShop))
          setShops(touropShops)
        }
      } catch (error) {
        console.error(error)
        toast.error('Error during merch search, please see console for error')
      }
      setIsLoading(false)
    })()
  }, [urlParameters])

  if (isLoading) {
    return <CircularProgress size={50} />
  }

  if (!merch) {
    return (
      <h1>{`No sanity document with ID "${urlParameters.merchSanityID}" found, please check the console of your naviagator, an error might be present`}</h1>
    )
  }

  return (
    <div>
      <h1>{merch.code}</h1>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item lg={6} xs={12}>
          <Paper style={{ height: '100%' }}>
            <Box p={2}>
              <MerchEditForm
                merch={merch}
                onSuccess={() => toast.success('Informations updated')}
                onFail={() => toast.error('Update failed, please see console for error')}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Paper style={{ height: '100%' }}>
            <Box p={2} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box mb={2}>
                {createGridLinkGroup('See on sanity', [
                  {
                    label: 'Sanity link',
                    url: `${merchandisingURL};${merch.id}`,
                  },
                ])}
              </Box>
              <Box mb={2}>{createGridLinkGroup('Preview URLs', getPreviewUrls(merch, shops))}</Box>
              <Box mb={2}>{createGridLinkGroup('Images', getImageUrls(merch, shops))}</Box>
              <Box component="div" style={{ marginTop: 'auto', display: 'flex', gap: '10px' }}>
                <LambdaProductListBuilderButton
                  merch={merch}
                  brandShops={shops.map(toBrandShop).flatMap(codes => codes)}
                />
                <LambdaVpvProductButton merch={merch} brandShops={shops.map(toBrandShop).flatMap(codes => codes)} />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

interface urlLabel {
  label: string
  url: string
}

function getPreviewUrls(merch: merchModel, shops: touropShop[]): urlLabel[] {
  const previewUrls: urlLabel[] = Object.entries(merch.previewUrls || []).map(([label, url]) => {
    return {
      label,
      url,
    }
  })
  const externalMerch = !!previewUrls.length
  const hasStarted = merch.startAt && merch.startAt <= new Date()
  shops.forEach(shop => {
    shop.locales.forEach(locale => {
      ;[true, false].forEach(isPreview => {
        if (!isPreview && !hasStarted) {
          return
        }
        let rootUrl = shop.rootUrl
        if (merch.noIndex && shop._id === 'AFFR') {
          rootUrl = 'https://vente-privee-lacollection.perfectstay.com/'
        }
        if (merch.noIndex && shop._id === 'EKFR') {
          rootUrl = 'https://vente-privee-thelist.perfectstay.com/'
        }
        if (merch.noIndex && shop._id === 'TOFR') {
          rootUrl = 'https://veepee-smartdeals.perfectstay.com/'
        }
        const label = `
        ${externalMerch ? 'Internal' : ''} 
        ${isPreview ? 'preview' : 'current'} 
        ${shop.name} (${locale})`
        const url = `${rootUrl}${locale}-${shop.sellingCountryCode}/merch?strategy=transactionFirst&code=${merch.code}${
          isPreview ? '&preview' : ''
        }`

        previewUrls.push({ label, url })
      })
    })
  })
  return previewUrls
}

function getImageUrls(merch: merchModel, shops: touropShop[]): urlLabel[] {
  let imageUrls: urlLabel[] = []
  shops.forEach(shop => {
    shop.locales.forEach(locale => {
      const imageFolder = `${shop._id.substring(0, 2)}${locale.toUpperCase()}`
      const url = `${imStrongUrl}/images/display/merchandising/${imageFolder}/${merch.code}`
      imageUrls = imageUrls.filter(image => image.url !== url).concat([{ label: imageFolder, url: url }])
    })
  })
  return imageUrls
}

function createGridLinkGroup(title: string, links: urlLabel[]) {
  return (
    <Grid item container direction={'column'}>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom={true}>
          {title}
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        {links.map(link => (
          <Grid key={`${link.label}-${link.url}`} item xs={12}>
            <Link href={link.url} target="_blank" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <OpenInNewIcon fontSize={'inherit'} />
              {link.label}
            </Link>
          </Grid>
        ))}
        {(!links || !links.length) && <Grid item>No link available</Grid>}
      </Grid>
    </Grid>
  )
}

const LambdaProductListBuilderButton = (props: { merch: merchModel; brandShops: string[] }) => {
  const [isRunning, setIsRunning] = useState(false)
  const [payloads, setPayloads] = useState<lambdaProductListBuilderPayload[]>([])

  useEffect(() => {
    setPayloads(
      props.brandShops.map(brandShopCode => {
        return {
          brandCode: brandShopCode,
          scope: 'index',
          merchCode: props.merch.code,
        }
      })
    )
  }, [props])

  const runLambda = () => {
    setIsRunning(true)
    runLambdaPayloads({
      payloads: payloads,
      lambdaName: lambdaProductListBuilderName,
      onFinally: () => setIsRunning(false),
      onSuccess: () => toast.success('Merch update successful'),
      onFailure: () => toast.error('Some merch update failed, see console for errors'),
      onTimeout: () =>
        toast.warning(
          'Failed to get server response in time, the process might still be running. Please check if it worked in a few minutes.'
        ),
    })
  }

  return <ButtonGeneric label="Force merch update" isDisabled={isRunning} onClick={runLambda} startIcon={<Sync />} />
}

const LambdaVpvProductButton = (props: { merch: merchModel; brandShops: string[] }) => {
  const [isRunning, setIsRunning] = useState(false)
  const [payloads, setPayloads] = useState<lambdaVpvProductPayload[]>([])

  useEffect(() => {
    setPayloads(
      props.brandShops.map(brandShopCode => {
        return {
          brandCode: brandShopCode,
          merchCode: props.merch.code,
          force: true,
        }
      })
    )
  }, [props])

  const runLambda = () => {
    setIsRunning(true)
    runLambdaPayloads({
      payloads: payloads,
      lambdaName: lambdaVpvProductName,
      onFinally: () => setIsRunning(false),
      onSuccess: () => toast.success('VP Merch update successful'),
      onFailure: () => toast.error('Some VP merch update failed, see console for errors'),
      onTimeout: () =>
        toast.warning(
          'Failed to get server response in time, the process might still be running. Please check if it worked in a few minutes.'
        ),
    })
  }

  return <ButtonGeneric label="Force Veepee Update" isDisabled={isRunning} onClick={runLambda} startIcon={<Sync />} />
}
const runLambdaPayloads = async (args: {
  payloads: any[]
  lambdaName: string
  onFinally: () => void
  onSuccess: () => void
  onFailure: () => void
  onTimeout: () => void
}) => {
  try {
    const responses = await Promise.all(args.payloads.map(payload => invokeLambda(payload, args.lambdaName)))
    const failedInvokes: { payload: any; error: string }[] = []
    responses.forEach((response, i) => {
      if (response.status !== 'SUCCESS') {
        failedInvokes.push({
          payload: args.payloads[i],
          error: response.error,
        })
      }
    })
    if (failedInvokes.length) {
      console.error(failedInvokes)
      args.onFailure()
    } else {
      args.onSuccess()
    }
  } catch (error) {
    console.error(error)
    args.onTimeout()
  } finally {
    args.onFinally()
  }
}

export default MerchEdit
