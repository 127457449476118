import { differenceInSeconds } from 'date-fns'

/*
 *  @description This function compute a string to a slugified version of the string
 *  A slug is a simplified version of a string that is often used in URLs, file names,
 *  and other contexts where spaces and special characters are not allowed or are not desirable.
 */
export const slugify = (text: string): string => {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeeiiiiooooouuuunc------'
  for (let i = 0, len = from.length; i < len; i++) {
    text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }
  return (
    text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/&/g, '-y-') // Replace & with 'and'
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      // eslint-disable-next-line prettier/prettier
      // eslint-disable-next-line no-useless-escape
      .replace(/\-\-+/g, '-')
  ) // Replace multiple - with single -
}

export const calculateDuration = (startDate: Date, endDate: Date) => {
  const durationMs = differenceInSeconds(new Date(endDate), new Date(startDate))
  const hours = Math.floor(durationMs / 3600)
  const minutes = Math.floor(durationMs % 3600) / 60

  let durationString = ''

  if (hours > 0) {
    durationString += `${hours} ${hours === 1 ? 'hour' : 'hours'}`
  }

  if (minutes > 0) {
    if (durationString.length > 0) {
      durationString += ' '
    }
    durationString += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  }

  return durationString
}
