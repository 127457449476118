import { Patcher } from '../api'
import { ENDPOINTS, patch } from '../api'
import { Sale } from '../model'

const usePatchSales = () => {
  const patchOneSale = async (oldSale: Sale, update: Sale) => {
    const patchSale = async (id: number, patcher: Patcher<Sale>) => {
      return await patch<Sale>({
        endpoint: ENDPOINTS.sales.suffix,
        id: id,
        patch: patcher,
      })
    }
    const patcher = new Patcher<Sale>(oldSale, update)

    return await patchSale(oldSale._id, patcher)
  }

  return {
    patchOneSale,
  }
}

export default usePatchSales
