import { IconButton, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import ButtonSearch from '../ButtonSearch'
import { KeyboardDateTimePicker } from '../formikFormComponents/KeyboardDateTimePicker'
import { Close } from '@material-ui/icons'

export interface promoCodeSearchFormValues {
  code?: string
  ongoingDate?: Date
}

const hasSearchValues = (values: promoCodeSearchFormValues) => {
  return (values?.code && values?.code?.length > 0) || values?.ongoingDate
}

const PromoCodePageForm = (props: {
  onSubmit: (values: promoCodeSearchFormValues) => Promise<void>
  setPromoCodesFormValues: (values: promoCodeSearchFormValues) => void
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialValues: promoCodeSearchFormValues = {
    code: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setIsSubmitting(true)
        await props.onSubmit(values)
        setSubmitting(false)
        setIsSubmitting(false)
      }}
    >
      {({ values = {}, resetForm }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  label="Ongoing"
                  name="ongoingDate"
                  className="fullWidth"
                  format="dd/MM/yyyy HH:mm:ss"
                  inputVariant="outlined"
                  component={KeyboardDateTimePicker}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="code"
                  label="Code"
                  variant="outlined"
                  size="small"
                  className="fullWidth"
                  component={TextField}
                />
              </Grid>
              <Grid container item xs={2}>
                <ButtonSearch isDisabled={isSubmitting} />
                {hasSearchValues(values) && (
                  <Tooltip
                    title="Clear search"
                    aria-label="Clear search"
                    onClick={() => {
                      props.setPromoCodesFormValues({} as promoCodeSearchFormValues)
                      resetForm()
                    }}
                  >
                    <IconButton aria-label="clear" style={{ marginLeft: '10px' }}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PromoCodePageForm
