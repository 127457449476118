import Button, { ButtonTypeMap } from '@material-ui/core/Button'
import IconButton, { IconButtonTypeMap } from '@material-ui/core/IconButton'
import React, { ButtonHTMLAttributes } from 'react'
import '../index.css'
import { useTheme } from '@material-ui/core/styles'

const iconButtonSize = (buttonSize: ButtonTypeMap['props']['size']): IconButtonTypeMap['props']['size'] =>
  buttonSize === 'small' ? 'small' : 'medium'

const ButtonGeneric = (props: {
  label?: string
  isDisabled?: boolean
  isDanger?: boolean
  type?: ButtonHTMLAttributes<ButtonTypeMap>['type']
  color?: ButtonTypeMap['props']['color']
  variant?: ButtonTypeMap['props']['variant']
  startIcon?: ButtonTypeMap['props']['startIcon']
  size?: ButtonTypeMap['props']['size']
  onClick?: () => void
}) => {
  const theme = useTheme()
  if (!props.label) {
    return (
      <IconButton
        style={props.isDanger ? { color: theme.palette.error.main } : {}}
        disabled={props.isDisabled}
        color={props.color || 'primary'}
        type={props.type}
        onClick={() => (props.onClick ? props.onClick() : '')}
        size={iconButtonSize(props.size)}
      >
        {props.startIcon}
      </IconButton>
    )
  }
  return (
    <Button
      style={props.isDanger ? { backgroundColor: theme.palette.error.main } : {}}
      variant={props.variant || 'contained'}
      disabled={props.isDisabled}
      color={props.color || 'primary'}
      type={props.type}
      startIcon={props.startIcon}
      size={props.size}
      onClick={() => (props.onClick ? props.onClick() : '')}
    >
      {props.label}
    </Button>
  )
}

export default ButtonGeneric
