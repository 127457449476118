import local from './local'
import test from './test'
import canary from './canary'
import live from './live'

export const stage = process.env.REACT_APP_STAGE
export const isLocal = !!process.env.REACT_APP_LOCAL

const envConfig = stage === 'live' ? live : stage === 'canary' ? canary : test

const localConfig = isLocal ? local : {}

const config = {
  ...envConfig,
  ...localConfig,
}

// localConfig will override config values if present
export default config
