/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { CircularProgress, Alert } from '@mui/material'
import FormControl from '@material-ui/core/FormControl'
import { Grid, Typography, InputLabel, MenuItem, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core'
import Save from '@material-ui/icons/Save'
import { Select, TextField, Checkbox } from 'formik-material-ui'
import { toast } from 'react-toastify'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { shop } from '../../model/shop'
import { fetchShops } from '../../api/sanityClient'
import { KeyboardDateTimePicker } from '../formikFormComponents/KeyboardDateTimePickerDefaultValue'
import {
  BatchStatus,
  CreditFormType,
  CreditType,
  CreditValidityPeriodType,
  DiscountType,
  FormMode,
  ValidityDurationType,
} from '../../model/credit'
import ButtonGeneric from '../ButtonGeneric'

const validationSchema = Yup.object({
  code: Yup.string().required('Required'),
  brandShopCodes: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  discountType: Yup.string().required('Required'),
  startAt: Yup.date().required('Required'),
  amount: Yup.number().required('Required'),
  validityPeriodType: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  label: Yup.string().required('Required'),
  validityStartAt: Yup.date().when('validityPeriodType', (validityPeriodType, schema) => {
    if (validityPeriodType === CreditValidityPeriodType.FIXED_DATES) {
      return schema.required('Required')
    } else {
      return schema
    }
  }),
  validityEndAt: Yup.date().when('validityPeriodType', (validityPeriodType, schema) => {
    if (validityPeriodType === CreditValidityPeriodType.FIXED_DATES) {
      return schema.required('Required')
    } else {
      return schema
    }
  }),
  validityDurationType: Yup.string().when('validityPeriodType', (validityPeriodType, schema) => {
    if (validityPeriodType === CreditValidityPeriodType.DURATION) {
      return schema.required('Required')
    } else {
      return schema
    }
  }),
  validityDuration: Yup.number().when('validityPeriodType', (validityPeriodType, schema) => {
    if (validityPeriodType === CreditValidityPeriodType.DURATION) {
      return schema.required('Required')
    } else {
      return schema
    }
  }),
})

const initialValuesDefault = {
  brandShopCodes: [],
  creditExistingMembers: false,
}

const CreditForm = ({
  initialValues = initialValuesDefault,
  mode,
  handleSubmit,
}: {
  initialValues?: CreditFormType
  mode: FormMode
  handleSubmit: (data: CreditFormType) => Promise<any>
}) => {
  const [shops, setShops] = useState<shop[]>([])

  useEffect(() => {
    fetchShops()
      .then(setShops)
      .catch(error => {
        console.error(error)
        toast.error('Failed to retrive shops, please see console for error')
      })
  }, [])

  const preSubmit = (values: CreditFormType) => {
    console.log('values', values)
    const submitValues = values
    if (typeof values?.brandShopCodes == 'string') {
      submitValues['brandShopCodes'] = [values?.brandShopCodes]
    }
    if (values?.discountType === DiscountType.CURRENCY) {
      delete submitValues.maxAmount
    }
    if (values?.validityPeriodType === CreditValidityPeriodType.FIXED_DATES) {
      delete submitValues.validityDuration
      delete submitValues.validityDurationType
    } else {
      delete submitValues.validityStartAt
      delete submitValues.validityEndAt
    }
    if (mode === FormMode.CREATE) {
      if (values?.type === CreditType.LOYALTY || values.creditExistingMembers) {
        submitValues['creditExistingMembers'] = true
      } else {
        submitValues['creditExistingMembers'] = false
      }
      if (submitValues['creditExistingMembers']) {
        submitValues['batch'] = {
          status: BatchStatus.INITIAL,
        }
      }
    }

    if (mode === FormMode.CLONE) {
      if (submitValues['creditExistingMembers']) {
        submitValues['batch'] = {
          status: BatchStatus.INITIAL,
        }
      }
    }

    return submitValues
  }

  return shops && shops.length > 0 ? (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => handleSubmit(preSubmit(values))}
    >
      {({ values, isSubmitting, setValues }) => {
        const isDiscountPercent = values?.discountType === DiscountType.PERCENT
        const isValidityFixedDate = values?.validityPeriodType === CreditValidityPeriodType.FIXED_DATES
        const isValidityDuration = values?.validityPeriodType === CreditValidityPeriodType.DURATION
        const cannotEditCredit = [BatchStatus.IN_PROGRESS].includes(values?.batch?.status || BatchStatus.INITIAL)

        return (
          <Form>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <FormControl className="fullWidth" variant="filled" required={true}>
                  <InputLabel htmlFor="brandShopCodes">Shop(s)</InputLabel>
                  <Field component={Select} name="brandShopCodes" inputProps={{ id: 'brandShopCodes' }}>
                    {shops.map(shop => (
                      <MenuItem key={shop.id} value={shop.id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Campaign start at"
                  name="startAt"
                  className="fullWidth"
                  inputVariant={'filled'}
                  format="dd/MM/yyyy HH:mm"
                  size="small"
                  required={true}
                  minDate={new Date()}
                  component={KeyboardDateTimePicker}
                  defaultValue={new Date(new Date().setHours(0, 1, 0, 0) + 86400000)}
                  helperText="Nb : for a Loyalty campaign, the campaign start date corresponds to the batch start time"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Campaign end at"
                  name="endAt"
                  className="fullWidth"
                  inputVariant={'filled'}
                  format="dd/MM/yyyy HH:mm"
                  size="small"
                  required={true}
                  {...(values?.startAt && { minDate: new Date(values?.startAt) })}
                  component={KeyboardDateTimePicker}
                  defaultValue={new Date(new Date().setHours(0, 1, 0, 0) + 86400000)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl className="fullWidth" variant="filled" required={true}>
                  <InputLabel htmlFor="type">Credit type</InputLabel>
                  <Field component={Select} name="type" inputProps={{ id: 'type' }}>
                    {Object.values(CreditType).map(creditType => (
                      <MenuItem key={creditType} value={creditType}>
                        {creditType.toString().replaceAll('_', '')}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="code"
                  label="Code"
                  variant="filled"
                  size="small"
                  className="fullWidth"
                  required={true}
                  {...(initialValues?.code && { disabled: true })}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="label"
                  label="Subtitle"
                  variant="filled"
                  size="small"
                  className="fullWidth"
                  component={TextField}
                  required={true}
                  helperText="Text to be displayed under the credit type. For the inscription credit, subtitle would be “Bienvenue”"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="description"
                  label="Description"
                  variant="filled"
                  size="small"
                  className="fullWidth"
                  required={true}
                  component={TextField}
                  helperText="Text to be displayed under the credit subtitle. For the inscription credit, the description would be “Pour vous remercier de votre inscription”"
                />
              </Grid>
              <Grid item xs={isDiscountPercent ? 4 : 6}>
                <FormControl className="fullWidth" variant="filled" required={true}>
                  <InputLabel htmlFor="discountType">Discount type</InputLabel>
                  <Field component={Select} name="discountType" inputProps={{ id: 'discountType' }}>
                    {Object.values(DiscountType).map(discountType => (
                      <MenuItem key={discountType} value={discountType}>
                        {discountType.toString().replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={isDiscountPercent ? 4 : 6}>
                <Field
                  name="amount"
                  label={isDiscountPercent ? 'Percentage (%)' : 'Amount (€)'}
                  variant="filled"
                  size="small"
                  type="number"
                  required={true}
                  className="fullWidth"
                  component={TextField}
                />
              </Grid>
              {isDiscountPercent && (
                <Grid item xs={4}>
                  <Field
                    name="maxAmount"
                    label="Maximum amount (€)"
                    variant="filled"
                    type="number"
                    size="small"
                    className="fullWidth"
                    helperText="Define the maximum value that the credit can be equal to (in the case of an automatic calculation)"
                    component={TextField}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <FormControl className="fullWidth" required={true}>
                  <InputLabel htmlFor="validityPeriodType">Validity period type</InputLabel>
                  <Field component={Select} name="validityPeriodType" inputProps={{ id: 'validityPeriodType' }}>
                    {Object.values(CreditValidityPeriodType).map(type => (
                      <MenuItem key={type} value={type}>
                        {type.toString().replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              {isValidityFixedDate && (
                <>
                  <Grid item xs={4}>
                    <Field
                      label="Start validity date"
                      name="validityStartAt"
                      className="fullWidth"
                      inputVariant={'filled'}
                      format="dd/MM/yyyy HH:mm"
                      size="small"
                      required={true}
                      minDate={new Date()}
                      component={KeyboardDateTimePicker}
                      defaultValue={new Date(new Date().setHours(0, 1, 0, 0))}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="End validity date"
                      name="validityEndAt"
                      className="fullWidth"
                      inputVariant={'filled'}
                      format="dd/MM/yyyy HH:mm"
                      size="small"
                      required={true}
                      {...(values?.validityStartAt && { minDate: new Date(values?.validityStartAt) })}
                      component={KeyboardDateTimePicker}
                      defaultValue={new Date(new Date().setHours(0, 1, 0, 0))}
                    />
                  </Grid>
                </>
              )}
              {isValidityDuration && (
                <>
                  <Grid item xs={4}>
                    <FormControl className="fullWidth" required={true}>
                      <InputLabel htmlFor="validityDurationType">Duration type</InputLabel>
                      <Field component={Select} name="validityDurationType" inputProps={{ id: 'validityDurationType' }}>
                        {Object.values(ValidityDurationType).map(type => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Duration"
                      name="validityDuration"
                      className="fullWidth"
                      inputVariant={'filled'}
                      type="number"
                      size="small"
                      required={true}
                      component={TextField}
                      helperText="Select the length of time the credit will be valid once it has been granted"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                {values?.type !== CreditType.LOYALTY ? (
                  <>
                    <FormControlLabel
                      control={
                        <Field
                          name="creditExistingMembers"
                          variant="filled"
                          size="small"
                          className="fullWidth"
                          component={Checkbox}
                          style={{ width: '50px' }}
                          checked={values?.creditExistingMembers}
                        />
                      }
                      label="Credit existing members"
                    />
                    <br />
                  </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MuiCheckbox checked={true} disabled={true} onChange={() => {}} size="small" />
                    <Typography variant="body1">Credit existing members</Typography>
                  </div>
                )}
                <Typography variant="caption">
                  The batch will be constituted using the elements specified above (code, subtitle, description, amount,
                  validity date, campaign start at*). The batch will be triggered at the date and time indicated in the
                  Campaign Start at field.
                </Typography>
                <Alert severity="warning" style={{ marginTop: '10px' }}>
                  Once the batch has started running (i.e. at campaign start date), it will no longer be possible to
                  cancel it or modify credit information (validity dates, etc.)
                </Alert>
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" style={{ marginTop: '20px' }}>
              <ButtonGeneric
                label="Save"
                isDisabled={isSubmitting || cannotEditCredit}
                startIcon={<Save />}
                type="submit"
                color="secondary"
              />
            </Grid>
          </Form>
        )
      }}
    </Formik>
  ) : (
    <CircularProgress size={50} />
  )
}

export default CreditForm
