import { useState } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { toast } from 'react-toastify'
import ButtonGeneric from '../ButtonGeneric'
import PromoCodeEditFormDialog from './PromoCodeEditFormDialog'
import PromoCodeSearchForm, { promoCodeSearchFormValues } from './PromoCodeSearchForm'
import PromoCodeSearchTable from './PromoCodeSearchTable'

const PromoCodeSearch = () => {
  const [promoCodesFormValues, setPromoCodesFormValues] = useState<promoCodeSearchFormValues>()

  const onSubmit = async (values: promoCodeSearchFormValues): Promise<void> => {
    setPromoCodesFormValues(values)
  }

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid container item justifyContent={'flex-end'}>
        <AddPromoCodeButton />
      </Grid>
      <Grid item>
        <Paper>
          <Box p={2}>
            <PromoCodeSearchForm onSubmit={onSubmit} setPromoCodesFormValues={setPromoCodesFormValues} />
          </Box>
        </Paper>
      </Grid>
      <Grid item>
        <PromoCodeSearchTable searchValues={promoCodesFormValues} />
      </Grid>
    </Grid>
  )
}

const AddPromoCodeButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  return (
    <div>
      <ButtonGeneric label={'New promocode'} startIcon={<Add />} onClick={() => setIsDialogOpen(true)} />
      <PromoCodeEditFormDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        onSuccess={() => toast.success('Promocode created')}
        promoCodeValue={undefined}
        onFail={() => toast.error('Error during creation, please see console for error')}
      />
    </div>
  )
}

export default PromoCodeSearch
