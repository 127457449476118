import axios from 'axios'
import config from '../config/config'
import { MerchProductsSearchForm, ProductsListingSearchForm, BRANDS_MAPPING } from '../model/crm'

export const marketingApiURL = config.marketingApiURL

let client = axios.create()
client.interceptors.request.use(config => {
  return config
})
client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export const fetchMerchProducts = (criteria: MerchProductsSearchForm): Promise<any> => {
  const brand = BRANDS_MAPPING[criteria.marketingCode as keyof typeof BRANDS_MAPPING]
  return client
    .get<any[]>(
      `https://${brand?.path}/preview/${brand?.shop}/${criteria?.marketingCode}/merchandising/${criteria.merchCode}.json`
    )
    .then(response => response.data)
}

export const fetchMerch = (criteria: MerchProductsSearchForm): Promise<any> => {
  const brand = BRANDS_MAPPING[criteria.marketingCode as keyof typeof BRANDS_MAPPING]
  return client
    .post<any>(`${marketingApiURL}/crm`, {
      url: `https://${brand?.path}/preview/${brand?.shop}/${criteria?.marketingCode}/merchandising/${criteria.merchCode}.json`,
    })
    .then(response => response.data)
}

export const fetchProductsListing = (criteria: ProductsListingSearchForm): Promise<any> => {
  const brand = BRANDS_MAPPING[criteria.marketingCode as keyof typeof BRANDS_MAPPING]
  return client
    .get<any[]>(`https://${brand?.path}/preview/${brand?.shop}/${criteria?.marketingCode}/products.json`)
    .then(response => response.data)
}

export const fetchProduct = (criteria: ProductsListingSearchForm): Promise<any> => {
  const brand = BRANDS_MAPPING[criteria.marketingCode as keyof typeof BRANDS_MAPPING]
  return client
    .post<any>(`${marketingApiURL}/crm`, {
      url: `https://${brand?.path}/preview/${brand?.shop}/${criteria?.marketingCode}/products.json`,
    })
    .then(response => response.data)
}
