import { Checkbox, Grid, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

type props = {
  headers: string[]
  withCheckbox?: boolean
  handleSelectAllClick: (checked: boolean) => void
  children: JSX.Element
}

const ListLayout = (props: props) => {
  const { headers, withCheckbox, handleSelectAllClick, children } = props

  return (
    <Grid container>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {withCheckbox && (
                <TableCell padding={'checkbox'} align={'center'} key={'checkbox'}>
                  <Checkbox onChange={event => handleSelectAllClick(event.target.checked)} />
                </TableCell>
              )}
              {headers.map((header, index) => (
                <TableCell align={'center'} key={index}>
                  <b>{header}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default ListLayout
