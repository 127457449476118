import { useState, useCallback } from 'react'
import { CircularProgress, Grid, FormControl, InputLabel, MenuItem, Button, IconButton } from '@material-ui/core'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material'
import { Select } from 'formik-material-ui'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { BRANDS, ProductsListingSearchForm } from '../../model/crm'
import { fetchProduct } from '../../api/crmClient'
import { KeyboardDatePicker } from '../formikFormComponents/KeyboardDatePicker'
import { toast } from 'react-toastify'
import { FormattedDate } from 'react-intl'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const validationSchema = Yup.object({
  marketingCode: Yup.string().required('Required'),
})

const convertUnixTimestampToDateInUTC = (timestamp: number) => {
  const dateInUTC = new Date(timestamp)
  dateInUTC.setUTCHours(0, 0, 0, 0) // Set the time to midnight UTC
  return dateInUTC
}

const convertLocalDateToUTCDate = (localDate: Date) => {
  const utcDate = new Date(localDate)
  utcDate.setUTCHours(0, 0, 0, 0) // Set the time to midnight UTC
  return utcDate
}

const compareDates = (date1: Date, date2: Date) => {
  return date1.getTime() === date2.getTime()
}

const CrmProducts = () => {
  const [searching, setSearching] = useState(false)
  const [result, setResult] = useState<any[]>()

  const handleSubmit = (formValues: ProductsListingSearchForm, formikBag: any) => {
    const { setSubmitting } = formikBag

    let filterDate = new Date()
    if (formValues?.date?.length > 0) {
      filterDate = new Date(formValues?.date)
    }

    setSearching(true)

    fetchProduct(formValues)
      .then(results => {
        const products = JSON.parse(results?.data)?.products || []
        setResult(
          products?.filter(({ endAt }: { endAt: number }) => {
            const filterDateUTC = convertLocalDateToUTCDate(filterDate)
            const endDateUTC = convertUnixTimestampToDateInUTC(endAt) // endAt is GTM + 2 => should be convert to GTM 0
            return compareDates(filterDateUTC, endDateUTC)
          })
        )
      })
      .catch(err => {
        console.error(err)
        toast.error('Error during search, please see console for error')
      })
      .finally(() => {
        setSearching(false)
        setSubmitting(false)
      })
  }

  const copyAllId = useCallback(() => {
    const allIds: string[] = result?.map(({ id }: { id: string }) => id) || []
    navigator.clipboard.writeText(allIds.join('\n'))
    toast.success('Copied')
  }, [result])

  return (
    <Formik
      enableReinitialize
      initialValues={{ marketingCode: '', date: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => handleSubmit(values as ProductsListingSearchForm, formikBag)}
    >
      {({ values }) => {
        return (
          <Form>
            <Grid item container spacing={2} style={{ display: 'flex', alignItems: 'center', minHeight: '80px' }}>
              <Grid item xs={3}>
                <FormControl className="fullWidth" variant="outlined" required={true}>
                  <InputLabel htmlFor="marketingCode" style={{ backgroundColor: 'white' }}>
                    Shop(s)
                  </InputLabel>
                  <Field
                    component={Select}
                    name="marketingCode"
                    inputProps={{ id: 'marketingCode' }}
                    disabled={searching}
                    variant="outlined"
                  >
                    {BRANDS.map(brand => (
                      <MenuItem key={brand.marketingCode} value={brand.marketingCode}>
                        {brand.marketingCode}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Field
                  label="Date"
                  name="date"
                  className="fullWidth"
                  inputVariant={'outlined'}
                  format="dd/MM/yyyy"
                  size="small"
                  {...(values.date && { InputLabelProps: { shrink: true } })}
                  component={KeyboardDatePicker}
                />
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Button disabled={searching} type="submit" color="primary" variant="contained">
                  {searching ? <CircularProgress size={50} /> : 'Search'}
                </Button>
              </Grid>
            </Grid>

            {searching ? (
              <CircularProgress size={50} />
            ) : (
              <>
                <Grid item container spacing={2}>
                  {result && (
                    <Grid item xs={12}>
                      Total: {result?.length} products
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {result && result?.length > 0 && (
                      <div style={{ height: '300px', overflowY: 'auto' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                Id
                                <IconButton color="primary" onClick={copyAllId}>
                                  <FileCopyIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Start at</TableCell>
                              <TableCell>End at</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {result.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                  <FormattedDate value={item.startAt} />
                                </TableCell>
                                <TableCell>
                                  <FormattedDate value={convertUnixTimestampToDateInUTC(item.endAt)} />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
export default CrmProducts
