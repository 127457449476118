import Grid from '@material-ui/core/Grid'
import { format } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import ButtonSearch from '../ButtonSearch'
import { KeyboardDatePicker } from '../formikFormComponents/KeyboardDatePicker'

export interface formValues {
  date: string
  filter: string
}
export function DatalayerForm(props: { onSubmit: (values: formValues) => Promise<void> }) {
  const initialValues: formValues = {
    date: format(new Date(), 'yyyy-MM-dd'),
    filter: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        await props.onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                name="date"
                className="fullWidth"
                component={KeyboardDatePicker}
                {...(values.date && { InputLabelProps: { shrink: true } })}
                inputVariant={'outlined'}
                label="Date"
                format="yyyy-MM-dd"
              />
            </Grid>
            <Grid item xs={3}>
              <Field name="filter" className="fullWidth" component={TextField} variant="outlined" label="Filter" />
            </Grid>
            <Grid item xs={2}>
              <ButtonSearch isDisabled={isSubmitting} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
