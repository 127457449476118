import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps as MuiKeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { FieldProps, getIn } from 'formik'
import React from 'react'
import { format } from 'date-fns'
interface KeyboardDatePickerProps
  extends FieldProps,
    Omit<MuiKeyboardDatePickerProps, 'name' | 'value' | 'error' | 'onChange'> {
  listenValueChange?: (newDate?: Date) => void
}

function fieldToKeyboardDatePicker({
  disabled,
  field,
  form: { isSubmitting, touched, errors, setFieldValue, setFieldError, setFieldTouched },
  ...props
}: KeyboardDatePickerProps): MuiKeyboardDatePickerProps {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError
  const valueChangeListener = props.listenValueChange
  delete props.listenValueChange
  return {
    ...props,
    ...field,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled ? disabled : isSubmitting,
    onChange: (picker, value) => {
      setFieldValue(
        field.name,
        picker instanceof Date && !isNaN(picker.valueOf()) ? format(picker, 'yyyy-MM-dd') : picker
      )
      setFieldTouched(field.name, true, false)
      if (valueChangeListener) {
        if (picker) {
          valueChangeListener(new Date(picker.getTime()))
        } else {
          valueChangeListener(undefined)
        }
      }
    },
    onBlur: (event: any) => {
      setFieldTouched(field.name, true, false)
    },
  }
}

export const KeyboardDatePicker = ({ children, ...props }: KeyboardDatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiKeyboardDatePicker {...fieldToKeyboardDatePicker(props)}>{children}</MuiKeyboardDatePicker>
    </MuiPickersUtilsProvider>
  )
}
