import React, { useState } from 'react'
import { PropsWithChildren } from '../../../model/common'

export type IProgressContext = {
  currentStep: number
  steps: number
  started: boolean
  start: (steps?: number) => void
  stop: () => void
  end: () => void
  next: () => void
  promisesWithProgress: (...promises: Array<Promise<any>>) => void
}

export const GlobalProgressContext = React.createContext<IProgressContext>({} as IProgressContext)

const GlobalProgressProvider = ({ children }: { children: PropsWithChildren<{}> }) => {
  const [started, setStarted] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [steps, setSteps] = useState<number>(1)

  const start = (steps: number = 1) => {
    setStarted(true)
    setCurrentStep(0)
    setSteps(steps)
  }

  const stop = () => setStarted(false)

  const end = () => {
    if (started) {
      setCurrentStep(steps)
      stop()
    }
  }

  const next = () => {
    if (started) {
      setCurrentStep(Math.min(currentStep + 1, steps))
    }
  }

  const promisesWithProgress = (...promises: Array<Promise<any>>) => {
    start(promises.length)
    promises.forEach(p => {
      p.then(x => {
        next()
        return x
      }).catch(x => {
        stop()
        return x
      })
    })
  }

  return (
    <GlobalProgressContext.Provider
      value={{ started, currentStep, steps, start, end, next, stop, promisesWithProgress }}
    >
      {children}
    </GlobalProgressContext.Provider>
  )
}

export default GlobalProgressProvider
