interface PropertyReference {
  _id: number
  bookmarks?: Bookmark[]
  competitivityScore: number
}

interface Bookmark {
  url: string
  metadata?: Record<string, any>
}

function getBookmarkAttribute(p: PropertyReference, key: string): number | undefined {
  if (p.bookmarks) {
    for (var bookmark of p.bookmarks) {
      if (bookmark.metadata) {
        let value = bookmark.metadata[key]
        if (value) {
          return value
        }
      }
    }
  }
  return undefined
}

export { getBookmarkAttribute }

export default PropertyReference
