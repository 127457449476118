/* eslint-disable react/style-prop-object */
/*
 *  @description This is a customized table based on MUI
 *  The basic props required to display data, filter, actions, etc. on a datagrid are:
 *  @param { array } columns => a list of item-defined column displays that contain the following properties:
 *      label: the title of the column
 *      type: the type of data to render in the cell
 *      name: the corresponding variable name of the data
 *      sortable: indicates whether the column can be sorted
 *      compute: a function applied to change the value of data before displaying it in the cell
 *      customElement: a customized element that will show on the cell
 *  @param { array } filters =>  a list of filters that contain the following properties:
 *      label: the title of the filter
 *      name: the variable name to filter
 *      options: the options that are available if the filter type is select
 *      type: the type of filter
 *  @param { array } data =>  an array of objects that contain the data to display
 *  @param { function } fetch => the endpoint used to fetch data. The endpoint must provide fetching data with searchCriteria.
 */
import React, { useEffect, useState } from 'react'
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl'
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from '@mui/material'
import { DatagridColumn, DatagridContext, DatagridProps, DataType } from './DatagridModel'
import TableSortCell from './TableSortCell'
import { SearchCriteria, SearchOrderBy } from '../../../model/SearchCriteria'
import DatagridToolbar from './DatagridToolbar'
import { LocalStorageEntity } from '../../../utils/localStorage'

const Datagrid = <T,>({
  name,
  columns = [],
  criteriaDefault = {},
  defaultHiddenColumns = [],
  data,
  actionsMutilple,
  fetch,
  filters,
}: DatagridProps<T>) => {
  const storage: LocalStorageEntity<string[]> = new LocalStorageEntity<string[]>(name + '_datagrid_columns')
  const [selected, setSelected] = useState<number[]>([])
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    Array.isArray(storage.load()) ? storage.load() : defaultHiddenColumns
  )
  const [criteria, setCriteria] = useState<SearchCriteria>(criteriaDefault)

  const handleSelect = (selected: number[]) => setSelected(selected)
  const isColumnVisible = (name: string): boolean => !hiddenColumns.includes(name)
  const toggleColumnVisibility = (name: string) => {
    const hiddenColumnsUpdated = hiddenColumns.includes(name)
      ? [...hiddenColumns.filter(n => n !== name)]
      : [name, ...hiddenColumns]
    setHiddenColumns(hiddenColumnsUpdated)
    storage.save(hiddenColumnsUpdated)
  }

  const handleSort = (name: string, order: SearchOrderBy) =>
    setCriteria({
      ...criteria,
      sortBy: name,
      orderBy: order,
    })
  const refreshAfterChange = () => (fetch ? fetch(criteria) : {})

  const renderTableCell = (cellData: any, row: T, column: DatagridColumn<T>) => {
    const columnWithoutData = [DataType.Actions, DataType.Custom]
    if (typeof cellData != 'boolean' && !cellData && !columnWithoutData.includes(column.type)) {
      return '-'
    }
    switch (column?.type) {
      case DataType.Date:
        return <FormattedDate value={new Date(cellData)} />
      case DataType.Time:
        return <FormattedTime value={new Date(cellData)} />
      case DataType.DateTime:
        const value = new Date(cellData)
        return (
          <>
            <FormattedDate value={value} /> - <FormattedTime value={value} />
          </>
        )
      case DataType.Currency:
        return <FormattedNumber value={cellData} style="currency" currency="EUR" />
      case DataType.Boolean:
        return typeof cellData == 'boolean' ? (cellData ? 'TRUE' : 'FALSE') : '-'
      case DataType.Percentage:
        return <FormattedNumber value={parseInt(cellData) / 100} maximumFractionDigits={2} style="percent" />
      case DataType.Actions:
        return column?.customElement
          ? React.createElement(column?.customElement, { entity: row, ...(fetch && { refreshAfterChange }) })
          : '-'
      default:
        return cellData
    }
  }

  const renderRow = (row: T, index: number) => {
    return columns.map((column: DatagridColumn<T>) => {
      const { name, align, compute } = column
      const cellComputedData = compute ? compute(row) : (row as Record<string, any>)[name]

      return isColumnVisible(name) ? (
        <TableCell align={align} key={`${index}-${name}`}>
          {renderTableCell(cellComputedData, row, column)}
        </TableCell>
      ) : (
        <></>
      )
    })
  }

  useEffect(() => {
    if (fetch) {
      fetch(criteria)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria])

  return (
    <DatagridContext.Provider
      value={{
        columns,
        filters,
        searchCriteria: criteria,
        updateSearchCriteria: setCriteria,
        selected,
        hiddenColumns,
        onSort: handleSort,
        onSelect: handleSelect,
        onToggleColumnVisibility: toggleColumnVisibility,
        isColumnVisible: isColumnVisible,
      }}
    >
      <Table>
        <TableHead>
          <DatagridToolbar />
          <TableRow>
            {actionsMutilple && <TableCell></TableCell>}
            {columns.map((column: DatagridColumn<T>) => {
              const { name, label, sortable } = column
              return isColumnVisible(name) ? (
                sortable ? (
                  <TableSortCell key={`head-sort-cell-${name}`} {...column} />
                ) : (
                  <TableCell key={`head-cell-${name}`}>{label}</TableCell>
                )
              ) : (
                ''
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {data && data.length > 0 ? (
            data.map((d, i) => {
              return (
                <TableRow hover key={`row-${i}`}>
                  {actionsMutilple && (
                    <TableCell key={1}>
                      <Checkbox checked={selected.indexOf(i) !== -1} onClick={() => {}} />
                    </TableCell>
                  )}
                  {renderRow(d, i)}
                </TableRow>
              )
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </DatagridContext.Provider>
  )
}

export default Datagrid
