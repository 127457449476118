import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useState } from 'react'
import ReactJson from 'react-json-view'
import { ConnectivityEvent } from '../../api/marketingClient'

interface Params {
  result?: ConnectivityEvent[]
}
export function ConnectivityResultTable(params: Params) {
  const { result } = params
  const [selected, setSelected] = useState<ConnectivityEvent>()
  if (!result) {
    return <></>
  }
  return (
    <>
      {selected && (
        <Dialog open={selected !== undefined} onClose={() => setSelected(undefined)} maxWidth="xl">
          <DialogContent>
            <ReactJson displayDataTypes={false} src={selected} />
          </DialogContent>
        </Dialog>
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {['Day', 'Level', 'Message', 'Error', 'Actions'].map((tableHeader, index) => (
                <TableCell align="left" key={index}>
                  {tableHeader}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {result.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.day}</TableCell>
                <TableCell>{item.level}</TableCell>
                <TableCell>{item.message}</TableCell>
                <TableCell>{item.data?.error}</TableCell>
                <TableCell>
                  <Button onClick={() => setSelected(item)}>Details</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
