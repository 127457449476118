import { useEffect, useState, useContext, memo, useCallback } from 'react'
import { ButtonGroup, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'
import { omit } from 'lodash'
import { GlobalProgressContext } from '../shared/GlobalProgress/GlobalProgressContext'
import { Credit, CreditFormType, FormMode } from '../../model/credit'
import { SearchCriteria } from '../../model/SearchCriteria'
import CreditForm from './CreditForm'
import { createCredit, deleteCredit, updateCredit } from '../../api/creditClient'
import { useAuth } from '../../providers/AuthContext'

enum CREDIT_ACTIONS {
  EDIT = 'EDIT',
  CLONE = 'CLONE',
  DELETE = 'DELETE',
}

const ModalTitle = {
  [CREDIT_ACTIONS.EDIT]: 'Edit',
  [CREDIT_ACTIONS.CLONE]: 'Clone',
  [CREDIT_ACTIONS.DELETE]: 'Delete',
}

const CreditActions = ({
  entity,
  refreshAfterChange,
}: {
  entity: Credit
  refreshAfterChange: (c?: SearchCriteria) => Promise<void>
}) => {
  const { authData } = useAuth()
  const { promisesWithProgress } = useContext(GlobalProgressContext)
  const [action, setAction] = useState<CREDIT_ACTIONS>()
  const [open, setOpen] = useState<boolean>(false)

  const handleActionClick = useCallback(
    (action: CREDIT_ACTIONS) => () => {
      setAction(action)
    },
    []
  )
  const handleClose = useCallback(() => {
    setAction(undefined)
    setOpen(false)
  }, [])
  const handleDelete = useCallback(() => {
    promisesWithProgress(
      deleteCredit(entity._id)
        .then(res => {
          refreshAfterChange()
          toast.success('Credit has been deleted successfully')
        })
        .catch(err => {
          toast.error('Failed to delete credit, please check console for error')
        })
        .finally(() => handleClose())
    )
  }, [entity, handleClose, promisesWithProgress, refreshAfterChange])
  const handleEdit = useCallback(
    async (data: CreditFormType) => {
      promisesWithProgress(
        updateCredit(entity._id, data)
          .then(res => {
            refreshAfterChange()
            toast.success('Credit has been updated successfully')
          })
          .catch(err => {
            toast.error('Failed to updated credit, please check console for error')
          })
          .finally(() => handleClose())
      )
    },
    [handleClose, promisesWithProgress, refreshAfterChange, entity._id]
  )
  const handleClone = useCallback(
    async (data: CreditFormType) => {
      promisesWithProgress(
        createCredit(data)
          .then(res => {
            refreshAfterChange()
            toast.success('Credit saved successfully')
          })
          .catch(err => {
            toast.error('Failed to save credit, please check console for error')
          })
          .finally(() => handleClose())
      )
    },
    [handleClose, promisesWithProgress, refreshAfterChange]
  )

  useEffect(() => {
    if (action) {
      setOpen(true)
    } else {
      handleClose()
    }
  }, [action, handleClose])

  return (
    <>
      <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
        <IconButton color="primary" disabled={!authData?.isAdmin} onClick={handleActionClick(CREDIT_ACTIONS.EDIT)}>
          <EditIcon />
        </IconButton>
        <IconButton color="primary" disabled={!authData?.isAdmin} onClick={handleActionClick(CREDIT_ACTIONS.CLONE)}>
          <FileCopyIcon />
        </IconButton>
        <IconButton color="primary" disabled={!authData?.isAdmin} onClick={handleActionClick(CREDIT_ACTIONS.DELETE)}>
          <DeleteIcon />
        </IconButton>
      </ButtonGroup>

      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>{action && ModalTitle[action]}</DialogTitle>
        <DialogContent>
          {action && action === CREDIT_ACTIONS.EDIT && (
            <CreditForm mode={FormMode.EDIT} initialValues={entity} handleSubmit={handleEdit} />
          )}
          {action && action === CREDIT_ACTIONS.CLONE && (
            <CreditForm
              mode={FormMode.CLONE}
              initialValues={omit(entity, ['code', '_id'])}
              handleSubmit={handleClone}
            />
          )}
          {action &&
            [CREDIT_ACTIONS.DELETE].includes(action) &&
            'This action is irreversible, this credit will no longer be visible.'}
        </DialogContent>
        {action && [CREDIT_ACTIONS.DELETE].includes(action) && (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default memo(CreditActions)
