import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PromoCodeEditForm from './PromoCodeEditForm'
import { promocode } from '../../model/promocode'

const PromoCodeEditFormDialog = (props: {
  open: boolean
  promoCodeValue?: promocode
  handleClose: () => void
  onSuccess?: () => void
  onFail?: () => void
}) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogContent>
        <PromoCodeEditForm
          promoCode={props.promoCodeValue}
          onSuccess={() => {
            if (props.onSuccess) props.onSuccess()
            props.handleClose()
          }}
          onFail={() => {
            if (props.onFail) props.onFail()
            props.handleClose()
          }}
        />
      </DialogContent>
      <DialogActions />
    </Dialog>
  )
}

export default PromoCodeEditFormDialog
