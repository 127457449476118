import AWS from 'aws-sdk'
import { isEmpty } from 'lodash'
import config from '../config/config'

AWS.config.update({
  region: config.awsRegion,
  apiVersion: 'latest',
  credentials: {
    accessKeyId: config.accessKeyId,
    secretAccessKey: config.secretAccessKey,
  },
})
const congitoClient = new AWS.CognitoIdentityServiceProvider()

export const getUsers = (
  cb: ((err: AWS.AWSError, data: AWS.CognitoIdentityServiceProvider.ListUsersResponse) => void) | undefined,
  paginationToken?: string,
  filter?: { key: string; value: string }
) => {
  const filters = `"${filter?.key}"^="${filter?.value}"`
  congitoClient.listUsers(
    {
      UserPoolId: config.cognitoUserPoolID,
      ...(!isEmpty(filter) && { Filter: filters }),
      ...(paginationToken && { PaginationToken: paginationToken }),
    },
    cb
  )
}
export const getGroups = (
  cb: ((err: AWS.AWSError, data: AWS.CognitoIdentityServiceProvider.ListGroupsResponse) => void) | undefined
) => {
  congitoClient.listGroups({ UserPoolId: config.cognitoUserPoolID }, cb)
}

export const getUsersInGroup = (
  groupName: string,
  cb: ((err: AWS.AWSError, data: AWS.CognitoIdentityServiceProvider.ListUsersResponse) => void) | undefined
) => {
  congitoClient.listUsersInGroup({ UserPoolId: config.cognitoUserPoolID, GroupName: groupName }, cb)
}

export const removeUserFromGroup = (
  cb: ((err: AWS.AWSError, data: AWS.CognitoIdentityServiceProvider.ListUsersResponse) => void) | undefined,
  userName: string,
  groupName: string
) => {
  congitoClient.adminRemoveUserFromGroup(
    { GroupName: groupName, Username: userName, UserPoolId: config.cognitoUserPoolID },
    cb
  )
}

export const addUserToGroup = (
  cb: ((err: AWS.AWSError, data: AWS.CognitoIdentityServiceProvider.ListUsersResponse) => void) | undefined,
  userName: string,
  groupName: string
) => {
  congitoClient.adminAddUserToGroup(
    { GroupName: groupName, Username: userName, UserPoolId: config.cognitoUserPoolID },
    cb
  )
}
