import { Button, Paper, Box } from '@material-ui/core'
import AirplayIcon from '@material-ui/icons/Airplay'
import React, { useEffect } from 'react'
import { User } from '../../model/user'
import { getAuthenticatorApi } from '../../api/authenticatorClient'
import { Link as RouterLink } from 'react-router-dom'

export default function UsersContainer() {
  const [users, setUsers] = React.useState<User[]>()
  useEffect(() => {
    getAuthenticatorApi().listUsers().then(setUsers)
  }, [])

  return (
    <Paper>
      <Box p={2}>
        {users && (
          <div>
            {users?.map(user => (
              <div>
                {user.otherAttributes['custom:marketingCode']}&nbsp;
                <RouterLink to={'/users/' + user.id}>{user.email}</RouterLink>
              </div>
            ))}
          </div>
        )}

        <Button startIcon={<AirplayIcon />} color="primary" variant="contained" component={RouterLink} to="/users/new">
          New connection
        </Button>
      </Box>
    </Paper>
  )
}
