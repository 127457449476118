import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react'
import { GlobalProgressContext } from './GlobalProgressContext'

/* tslint:disable no-var-requires */
const ProgressBar = require('react-progress-bar-plus')

const progressbarColor = (t: Theme) => t.palette.secondary.main

const useStyles = makeStyles((t: Theme) => ({
  '@global': {
    '.react-progress-bar-percent': {
      background: progressbarColor(t),
      boxShadow: `0 0 10px ${progressbarColor(t)}, 0 0 5px ${progressbarColor(t)}`,
      height: 2,
      transition: t.transitions.create('all', { duration: 400 }),
    },
    '.react-progress-bar': {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      visibility: 'visible',
      opacity: 1,
      transition: 'all 400ms',
      zIndex: 9999,
    },
    '.react-progress-bar-hide': {
      opacity: 0,
      visibility: 'hidden',
      zIndex: -10,
    },
  },
}))
const INITIAL_PERCENT = 10
const GlobalProgressBar = () => {
  const style = useStyles()
  const { currentStep, steps, started } = useContext(GlobalProgressContext)

  const getPercent = () => {
    return INITIAL_PERCENT + ((100 - INITIAL_PERCENT) / steps) * currentStep
  }

  return started ? (
    <ProgressBar style={style} percent={getPercent()} autoIncrement={started} spinner={false} intervalTime={100} />
  ) : (
    <></>
  )
}

export default GlobalProgressBar
