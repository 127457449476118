import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MerchEditForm from './MerchEditForm'
import { merch } from '../model/merch'

const MerchEditFormDialog = (props: {
  open: boolean
  merchValue?: merch
  handleClose: () => void
  onSuccess?: () => void
  onFail?: () => void
}) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogContent>
        <MerchEditForm
          merch={props.merchValue}
          onSuccess={() => {
            if (props.onSuccess) props.onSuccess()
            props.handleClose()
          }}
          onFail={() => {
            if (props.onFail) props.onFail()
            props.handleClose()
          }}
        />
      </DialogContent>
      <DialogActions />
    </Dialog>
  )
}

export default MerchEditFormDialog
