import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Field, Form, Formik } from 'formik'
import { Select } from 'formik-material-ui'
import React, { useEffect, useState } from 'react'
import { getAccounts, getTables, table } from '../../api/np6Client'
import ButtonSearch from '../ButtonSearch'

export interface np6SearchFormValues {
  account: string
  tableId: number | ''
}
var oldAccount = ''

const Np6PageForm = (props: { onSubmit: (values: np6SearchFormValues) => Promise<void> }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [accounts, setAccounts] = useState<string[]>([])
  const [tables, setTables] = useState<table[]>([])
  const initialValues: np6SearchFormValues = {
    account: '',
    tableId: '',
  }
  useEffect(() => {
    getAccounts().then(setAccounts)
  }, [])

  const handleAccount = function (account?: string) {
    if (account && account !== oldAccount) {
      oldAccount = account
      getTables(account).then(setTables)
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setIsSubmitting(true)
        await props.onSubmit(values)
        setSubmitting(false)
        setIsSubmitting(false)
      }}
    >
      {({ values }) => (
        <Form>
          <Grid container justify={'center'}>
            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <FormControl className="formik-select--outlined fullWidth" variant="outlined">
                  <InputLabel id="account-label">Account</InputLabel>
                  <Field
                    labelId="account-label"
                    component={Select}
                    name="account"
                    variant="outlined"
                    className="formik-select"
                  >
                    {handleAccount(values.account)}
                    {accounts.map(account => {
                      return (
                        <MenuItem key={account} value={account}>
                          {account}
                        </MenuItem>
                      )
                    })}
                  </Field>
                  <FormHelperText />
                </FormControl>
              </Grid>
              {values.account && (
                <Grid item xs={4}>
                  <FormControl className="formik-select--outlined fullWidth" variant="outlined">
                    <InputLabel id="table-label">Table</InputLabel>
                    <Field labelId="table-label" component={Select} name="tableId" variant="outlined">
                      {tables.map(table => {
                        return (
                          <MenuItem key={table.id} value={table.id}>
                            {table.name} ({table.id})
                          </MenuItem>
                        )
                      })}
                    </Field>
                    <FormHelperText />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={2}>
                <ButtonSearch isDisabled={isSubmitting} />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default Np6PageForm
