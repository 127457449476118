import { compare, Operation } from 'fast-json-patch'

interface IPatcher<T> {
  old: T
  update: T
  getOperation(): Operation[]
}

class Patcher<T> implements IPatcher<T> {
  old: T
  update: T

  constructor(old: T, update: T) {
    this.old = old
    this.update = update
  }
  getOperation(): Operation[] {
    const { old, update } = this

    return compare(old, update)
  }
}

export default Patcher
