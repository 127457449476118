import { Button, Paper, Box, Grid, IconButton, Tooltip, TextField } from '@material-ui/core'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material'
import React, { useEffect } from 'react'
import { ADMIN_GROUP_NAME, mappingCognitoUserToUser, User } from '../../model/user'
import { getUsers, getUsersInGroup, addUserToGroup, removeUserFromGroup } from '../../api/awsClient'
import { toast } from 'react-toastify'
import { FormattedDate } from 'react-intl'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import { useAuth } from '../../providers/AuthContext'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbarBtn: {
    marginLeft: '10px',
  },
  showMore: {
    marginBottom: '10px',
  },
})

export default function RolesContainer() {
  const [users, setUsers] = React.useState<User[]>()
  const [admins, setAdmins] = React.useState<string[]>()
  const [globalSearch, setGlobalSearch] = React.useState<string>('')
  const [nextPageToken, setNextPageToken] = React.useState<string>()
  const { authData } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    fetchingData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchingData = () => {
    fetchAdmin()
    getUsers((err, data) => {
      if (err) {
        console.error(err)
        toast.error('Error during fetching user, please see console for error')
      } else {
        setUsers(mappingCognitoUserToUser(data?.Users))
        setNextPageToken(data?.PaginationToken)
      }
    })
  }

  const fetchAdmin = () => {
    getUsersInGroup(ADMIN_GROUP_NAME, (err, data) => {
      if (err) {
        console.error(err)
        toast.error('Error during fetching user role, please see console for error')
      } else {
        setAdmins(mappingCognitoUserToUser(data?.Users)?.map((u: { email: any }) => u.email))
      }
    })
  }

  const handleShowMoreUsers = () => {
    getUsers((err, data) => {
      if (err) {
        console.error(err)
        toast.error('Error during fetching user, please see console for error')
      } else {
        const newUsers = [...(users as User[]), ...mappingCognitoUserToUser(data?.Users)]
        setUsers(newUsers)
        setNextPageToken(data?.PaginationToken)
      }
    }, nextPageToken)
  }

  const addAdmin = (userName: string) => {
    addUserToGroup(
      (err: any, data: any) => {
        if (err) {
          console.error(err)
          toast.error('Error during add user to admin group, please see console for error')
        } else {
          fetchAdmin()
          toast.success('User was add to admin group')
        }
      },
      userName,
      ADMIN_GROUP_NAME
    )
  }

  const removeAdmin = (userName: string) => {
    removeUserFromGroup(
      (err: any, data: any) => {
        if (err) {
          console.error(err)
          toast.error('Error during remove user from admin group, please see console for error')
        } else {
          fetchAdmin()
          toast.success('User was removed from admin group')
        }
      },
      userName,
      ADMIN_GROUP_NAME
    )
  }

  const handleSearch = () => {
    if (globalSearch && globalSearch?.length > 0) {
      getUsers(
        (err, data) => {
          if (err) {
            console.error(err)
            toast.error('Error during fetching user, please see console for error')
          } else {
            setUsers(mappingCognitoUserToUser(data?.Users))
            setNextPageToken(data?.PaginationToken)
          }
        },
        undefined,
        { key: 'email', value: globalSearch }
      )
    }
  }

  const handleReset = () => {
    setGlobalSearch('')
    fetchingData()
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid item xs={12}>
          <TextField
            label="Search field"
            type="search"
            variant="outlined"
            onChange={e => setGlobalSearch(e?.target?.value)}
            value={globalSearch}
          />
          <Button className={classes.toolbarBtn} variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button className={classes.toolbarBtn} variant="contained" color="primary" onClick={handleReset}>
            Clear
          </Button>
          <br />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            {users && users?.length > 0 && (
              <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Uuid</TableCell> */}
                      <TableCell>Email</TableCell>
                      {/* <TableCell>Fullname</TableCell> */}
                      <TableCell>Created at</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users
                      .filter(u => admins?.includes(u.email))
                      .map((user, index) => (
                        <TableRow key={index}>
                          {/* <TableCell>{user.uuid}</TableCell> */}
                          <TableCell>{user.email}</TableCell>
                          {/* <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell> */}
                          <TableCell>
                            <FormattedDate value={user.createdAt} />
                          </TableCell>
                          <TableCell>{user.status}</TableCell>
                          <TableCell>{admins?.includes(user.email) ? 'Admin' : 'User'}</TableCell>
                          <TableCell>
                            <Tooltip title="Remove from admin group" placement="top">
                              <IconButton
                                disabled={!authData.isAdmin}
                                color="primary"
                                onClick={() => removeAdmin(user?.uuid as string)}
                              >
                                <PersonAddDisabledIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    {users
                      .filter(u => !admins?.includes(u.email))
                      .map((user, index) => (
                        <TableRow key={index}>
                          {/* <TableCell>{user.uuid}</TableCell> */}
                          <TableCell>{user.email}</TableCell>
                          {/* <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell> */}
                          <TableCell>
                            <FormattedDate value={user.createdAt} />
                          </TableCell>
                          <TableCell>{user.status}</TableCell>
                          <TableCell>{admins?.includes(user.email) ? 'Admin' : 'User'}</TableCell>
                          <TableCell>
                            <Tooltip title="Add to admin group" placement="top">
                              <IconButton
                                disabled={!authData.isAdmin}
                                color="primary"
                                onClick={() => addAdmin(user?.uuid as string)}
                              >
                                <PersonAddIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {nextPageToken && (
              <Button classNames={classes.showMore} variant="contained" color="primary" onClick={handleShowMoreUsers}>
                Show more users
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
