import { useState } from 'react'
import { createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import ButtonGeneric from '../../components/ButtonGeneric'

interface Props {
  handleClick: (action: string) => void
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

const Actions = (props: Props) => {
  const classes = useStyles()
  const [action, setAction] = useState('')
  const { handleClick, disabled } = props

  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={2}>
        <FormControl className={`${classes.formControl} formik-select--outlined`} variant="outlined">
          <InputLabel>Select your action</InputLabel>
          <Select
            disabled={disabled}
            id="actions"
            value={action}
            onChange={event => setAction(event.target.value as string)}
            variant="outlined"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'include'}>Include</MenuItem>
            <MenuItem value={'exclude'}>Exclude</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <ButtonGeneric label={'Apply'} isDisabled={action === '' || disabled} onClick={() => handleClick(action)} />
      </Grid>
    </Grid>
  )
}

export default Actions
