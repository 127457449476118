import axios, { AxiosInstance } from 'axios'
import { cognitoToken } from '.'
import config from '../config/config'
import { User } from '../model/user'

export const authenticatorApiURL = config.authenticatorApiURL

export interface AuthenticatorApi {
  listUsers: () => Promise<User[]>
  getUser: (id: string) => Promise<User>
  updateUser: (id: string, user: User) => Promise<User>
  createUser: (user: User) => Promise<User>
}

let api: AuthenticatorApi | null = null

export function getAuthenticatorApi(): AuthenticatorApi {
  if (api !== null) {
    return api
  }

  const client = axios.create({
    baseURL: `${config.authenticatorApiURL}`,
  })
  client.interceptors.request.use(config => {
    config.headers!['Authorization'] = cognitoToken
    return config
  })
  client.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error?.response?.data?.message) {
        return Promise.reject(error.response.data.message)
      }
      if (error?.response?.data?.detail) {
        return Promise.reject(error.response.data.detail)
      }
      return Promise.reject(error)
    }
  )

  return {
    listUsers: listUsers(client),
    getUser: getUser(client),
    updateUser: updateUser(client),
    createUser: createUser(client),
  }
}

function listUsers(client: AxiosInstance): () => Promise<User[]> {
  return () => client.get('/admin/user/partner/').then(response => response.data)
}

function getUser(client: AxiosInstance): (id: string) => Promise<User> {
  return (id: string) => client.get('/admin/user/partner/' + id).then(response => response.data)
}

function updateUser(client: AxiosInstance): (id: string, user: User) => Promise<User> {
  return (id: string, obj: User) => client.post('/admin/user/partner/' + id, obj).then(response => response.data)
}

function createUser(client: AxiosInstance): (obj: User) => Promise<User> {
  return (obj: User) => client.put('/admin/user/partner/', obj).then(response => response.data)
}
