import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: { main: '#2196f3', dark: '#1769aa', light: ' #4dabf5' },
    secondary: { main: '#f50057', dark: '#ab003c', light: ' #f73378' },
  },
  props: {
    MuiPaper: {
      variant: 'outlined',
      elevation: 0,
    },
    MuiFormControl: {
      size: 'small',
    },
    MuiTextField: {
      variant: 'filled',
      size: 'small',
    },
    MuiSelect: {
      variant: 'filled',
      inputProps: { size: 'small' },
    },
  },
  overrides: {
    MuiPaper: {
      root: {},
    },
  },
})

export default theme
