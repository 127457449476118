import { Grid, Paper, Box, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { get } from '../../api/promoCodesClient'
import { promocode as promoCodeModel } from '../../model/promocode'
import PromoCodeEditForm from './PromoCodeEditForm'

const PromoCodeEdit = () => {
  const [promoCode, setPromoCode] = useState<promoCodeModel>()
  const [isLoading, setIsLoading] = useState(true)
  const [urlParameters] = useState<{ id?: string }>(useParams())

  useEffect(() => {
    if (!urlParameters.id) {
      return
    }
    get(urlParameters.id)
      .then(setPromoCode)
      .catch((error: any) => {
        console.log(error)
        toast.error(error.message as string)
      })
      .finally(() => setIsLoading(false))
  }, [urlParameters])

  if (isLoading) {
    return <CircularProgress size={50} />
  }

  if (!promoCode) {
    return (
      <h1>{`No promocode with ID "${urlParameters.id}" found, please check the console of your naviagator, an error might be present`}</h1>
    )
  }

  return (
    <div>
      <h1>{promoCode.code}</h1>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <Paper>
            <Box p={2}>
              <PromoCodeEditForm
                promoCode={promoCode}
                onSuccess={() => toast.success('Informations updated')}
                onFail={error => toast.error(error)}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default PromoCodeEdit
