import axios from 'axios'
import { cognitoToken } from '.'
import { promoCodeSearchFormValues } from '../components/promoCodes/PromoCodeSearchForm'
import config from '../config/config'
import { promocode } from '../model/promocode'

export const promoCodesApiURL = config.promoCodesApiURL

let client = axios.create()
client.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${cognitoToken}`
  return config
})
client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export const search = (searchParams: promoCodeSearchFormValues): Promise<promocode[]> => {
  return client
    .get<promocode[]>(`${promoCodesApiURL}/admin/search`, {
      params: {
        ...(searchParams?.code && { code: searchParams?.code }),
        ...(searchParams?.ongoingDate && { ongoingDate: new Date(searchParams?.ongoingDate).getTime() }),
      },
    })
    .then(response => response.data)
}

export const edit = (promocode: promocode): Promise<promocode> => {
  return client.post<promocode>(`${promoCodesApiURL}/admin/promocode`, promocode).then(response => response.data)
}

export const get = (id: string): Promise<promocode> => {
  return client.get<promocode>(`${promoCodesApiURL}/admin/promocode/` + id).then(response => response.data)
}

export const remove = (id: string): Promise<void> => {
  return client.delete<void>(`${promoCodesApiURL}/admin/promocode/` + id).then(response => response.data)
}
