import axios from 'axios'
import { cognitoToken } from '.'
import config from '../config/config'
import { Credit, CreditFormType } from '../model/credit'
import { SearchCriteria } from '../model/SearchCriteria'

export const creditApiURL = config.creditApiURL

let client = axios.create()
client.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${cognitoToken}`
  return config
})
client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export const searchCredit = (criteria?: SearchCriteria): Promise<Credit[]> => {
  return client.post<Credit[]>(`${creditApiURL}/search`, criteria).then(response => response.data)
}

export const createCredit = (data: CreditFormType): Promise<Credit> => {
  return client.post<Credit>(`${creditApiURL}/`, data).then(response => response.data)
}

export const updateCredit = (id: string, data: CreditFormType): Promise<Credit> => {
  return client.put<Credit>(`${creditApiURL}/${id}`, data).then(response => response.data)
}

export const deleteCredit = (id: string): Promise<{}> => {
  return client.delete<Credit[]>(`${creditApiURL}/${id}`).then(response => response.data)
}
