import { DataGridFilterOption } from './DatagridModel'

/*
 *  @description This function takes an enum object as an input and returns an array of options suitable for a data grid filter.
 */
export const enum2options = <T extends Record<string, string>>(enumObject: T): DataGridFilterOption[] => {
  const enumValues = Object.values(enumObject) as string[]
  return enumValues.map(option => ({
    label: option.toString().replaceAll('_', ' '),
    value: option,
  }))
}
