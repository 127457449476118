import React, { useEffect, useState } from 'react'
import { FormAutocompleteField } from './FormAutocompleteField'
import { ENDPOINTS, fetch } from '../../api'

export const GenericComboboxAutocomplete = (props: {
  dbType: string // the database table (or collection)
  label?: string // the name of the field that will be displayed in grey above the value (ex : "My Country num 1")
  multiple?: boolean
  required?: boolean
  disabled?: boolean
  field?: any
  form?: any
}) => {
  const {
    field: { name, value },
    form: { setTouched, setFieldValue },
  } = props
  const [options, setOptions] = useState<any[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [valueObj, setValueObj] = useState<any>(props.multiple ? [] : null)

  useEffect(() => {
    if (!inputValue) {
      setOptions([])
      return
    }
    fetch<any>({
      endpoint: '/' + props.dbType,
      query: new Map([['q', inputValue]]),
    })
      .then(options => setOptions(options || []))
      .catch(e => console.log(e))
  }, [inputValue, props.dbType])

  useEffect(() => {
    if (!value || value.length === 0) {
      setValueObj(props.multiple ? [] : null)
      return
    }
    fetch<any>({
      endpoint: ENDPOINTS.place.suffix,
      ids: value,
    })
      .then(v => setValueObj(props.multiple ? v || [] : v[0]))
      .catch(e => console.log(e))
  }, [value, props.multiple])

  return (
    <FormAutocompleteField
      name={name}
      label={props.label}
      options={options}
      multiple={props.multiple}
      value={valueObj}
      required={props.required}
      disabled={props.disabled}
      getOptionLabel={(option: any) => option?.name || ''}
      getOptionSelected={(option: any, value: any) => option?._id === value?._id}
      onBlur={() => setTouched({ [name]: true })}
      onChange={(_: any, newValueObj: any) => {
        console.log(newValueObj)
        const newValueId = props.multiple ? newValueObj.map((v: any) => v._id) : newValueObj._id
        setValueObj(newValueObj)
        setFieldValue(name, newValueId)
      }}
      onInputChange={(_: any, newInputValue: any) => {
        setInputValue(newInputValue)
      }}
    />
  )
}
