export type MerchProductsSearchForm = {
  merchCode: string
  marketingCode: string
  productIds?: string
}

export type ProductsListingSearchForm = {
  marketingCode: string
  date: any
}

export const BRANDS = [
  { marketingCode: 'AFFR', path: 'lacollection.airfrance.fr', shop: 'fr-FR' },
  { marketingCode: 'TOFR', path: 'holidays.transavia.fr', shop: 'fr-FR' },
  { marketingCode: 'EKFR', path: 'emiratesholidays.fr', shop: 'fr-FR' },
]

export const BRANDS_MAPPING = {
  AFFR: {
    path: 'lacollection.airfrance.fr',
    shop: 'fr-FR',
  },
  TOFR: {
    path: 'holidays.transavia.fr',
    shop: 'fr-FR',
  },
  EKFR: {
    path: 'emiratesholidays.fr',
    shop: 'fr-FR',
  },
}
