import { useContext, memo, useCallback } from 'react'
import { Box } from '@mui/material'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatagridContext } from '../DatagridModel'

const DatagridFilterDate = ({ name }: { name: string }) => {
  const { filters, searchCriteria, updateSearchCriteria } = useContext(DatagridContext)
  const filter = filters?.find(f => f.name === name)
  const handleChange = useCallback(
    (value: any) => {
      updateSearchCriteria({
        ...searchCriteria,
        filterBy: {
          ...searchCriteria.filterBy,
          [name]: value,
        },
      })
    },
    [name, searchCriteria, updateSearchCriteria]
  )

  return filter ? (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker label={filter.label} value={searchCriteria?.filterBy?.[name] || null} onChange={handleChange} />
      </LocalizationProvider>
    </Box>
  ) : (
    <></>
  )
}

export default memo(DatagridFilterDate)
