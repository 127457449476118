import { useState } from 'react'

interface Item {
  index: string
}

const useSetSelectedItems = () => {
  const [selectedItems, setSelectedItems] = useState<Item[]>([])

  const handleSelect = (index: string) => {
    const itemIdx = selectedItems.findIndex(item => item.index === index)
    let newSelected: Item[] = []

    if (itemIdx === -1) {
      newSelected = newSelected.concat(selectedItems, { index: index })
    } else if (itemIdx === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1))
    } else if (itemIdx === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1))
    } else if (itemIdx > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, itemIdx), selectedItems.slice(itemIdx + 1))
    }

    setSelectedItems(newSelected)
  }

  const handleSelectAll = (indexes: string[]) => {
    setSelectedItems(
      indexes.map(index => {
        return { index: index, selected: true }
      })
    )
  }

  const isItemSelected = (index: string) => selectedItems.findIndex(item => item.index === index) !== -1

  return {
    selectedItems,
    handleSelect,
    handleSelectAll,
    isItemSelected: isItemSelected,
    resetSelectedItems: () => setSelectedItems([]),
  }
}

export type { Item }

export default useSetSelectedItems
