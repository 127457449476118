import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import Crm from './components/crm/Crm'
import Credits from './components/Credits/Credits'
import MerchEdit from './components/MerchEdit'
import MerchSearch from './components/MerchSearch'
import Np6Search from './components/np6/Np6Search'
import PromoCodeEdit from './components/promoCodes/PromoCodeEdit'
import PromoCodeSearch from './components/promoCodes/PromoCodeSearch'
import UserContainer from './components/users/UserContainer'
import UsersContainer from './components/users/UsersContainer'
import GlobalProgressBar from './components/shared/GlobalProgress/GlobalProgressBar'
import { DatalayerContainer } from './components/datalayer/datalayer'
import GlobalProgressProvider from './components/shared/GlobalProgress/GlobalProgressContext'
import { DashboardLayout } from './layouts'
import { AuthProvider, useAuth } from './providers/AuthContext'
import { Filter } from './pages/filter'
import { setCognitoToken } from './api'
import { initClient } from './api/sanityClient'
import theme from './theme'

import 'react-toastify/dist/ReactToastify.css'
import RolesContainer from './components/roles/RolesContainer'

const App = () => (
  <>
    <CssBaseline />
    <IntlProvider locale="fr">
      <ThemeProvider theme={theme}>
        <GlobalProgressProvider>
          <AuthProvider>
            <InternalRouter />
            <ToastContainer position="bottom-center" newestOnTop transition={Slide} />
          </AuthProvider>
        </GlobalProgressProvider>
      </ThemeProvider>
    </IntlProvider>
  </>
)

const ProtectedRoute = ({ component: Component, isAdmin, ...rest }) => {
  return <Route {...rest} render={props => (isAdmin ? <Component {...props} /> : <Redirect to="/" />)} />
}

const InternalRouter = () => {
  const { authData, updateUserInfo } = useAuth()
  useEffect(() => {
    if (!authData.cognitoToken) {
      updateUserInfo()
    }
  }, [authData, updateUserInfo])

  if (!authData.cognitoToken) {
    return <div>Logging, you should be redirected soon</div>
  }
  initClient(authData.sanityToken)
  setCognitoToken(authData.cognitoToken)
  return (
    <>
      <GlobalProgressBar />
      <Router>
        <DashboardLayout>
          <Switch>
            <Route path={['/login', '/logout']}>
              <Redirect to="/" />
            </Route>
            <Route exact path="/">
              <Redirect to="/merch" />
            </Route>
            <Route path="/merch/:merchSanityID">
              <MerchEdit />
            </Route>
            <Route path="/merch">
              <MerchSearch />
            </Route>
            <Route path="/promocode/:id">
              <PromoCodeEdit />
            </Route>
            <Route path="/promocode">
              <PromoCodeSearch />
            </Route>
            <Route path="/np6">
              <Np6Search />
            </Route>
            <Route exact path="/users">
              <UsersContainer />
            </Route>
            <Route exact path="/users/:id">
              <UserContainer />
            </Route>
            <Route path="/filter">
              <Filter />
            </Route>
            <Route path="/credits">
              <Credits />
            </Route>
            <Route path="/crm">
              <Crm />
            </Route>
            <Route path="/datalayer">
              <DatalayerContainer />
            </Route>
            <ProtectedRoute path="/roles" isAdmin={authData?.isAdmin} component={RolesContainer} />
            <Route>Not found</Route>
          </Switch>
        </DashboardLayout>
      </Router>
    </>
  )
}

export default App
