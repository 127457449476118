import { useCallback, useState } from 'react'
import {
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  TableHead,
  TextField as MuiTextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Select, TextField } from 'formik-material-ui'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { BRANDS, MerchProductsSearchForm } from '../../model/crm'
import { fetchMerch } from '../../api/crmClient'
import { toast } from 'react-toastify'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const validationSchema = Yup.object({
  marketingCode: Yup.string().required('Required'),
  merchCode: Yup.string().required('Required'),
})

const filterProducts = (checkList: string[], listProducts: string[]) => {
  const nonExistList = [] as string[]
  const existList = [] as string[]
  if (listProducts?.length === 0 || checkList?.length === 0) {
    return [existList, existList]
  }

  checkList.forEach((checkId: string) => {
    if (listProducts.includes(checkId)) {
      existList.push(checkId)
    } else {
      nonExistList.push(checkId)
    }
  })
  return [existList, nonExistList]
}

const CrmMerchProducts = () => {
  const [searching, setSearching] = useState(false)
  const [result, setResult] = useState<any[]>()
  const [productInexistent, setProductInexistent] = useState<string[]>([])
  const [productExistent, setProductExistent] = useState<string[]>([])
  const [filterProductIds, setFilterProductIds] = useState<string[]>([])
  const handleSubmit = (formValues: MerchProductsSearchForm, formikBag: any) => {
    const { setSubmitting } = formikBag

    setResult([])
    setProductInexistent([])
    setProductExistent([])
    setSearching(true)

    fetchMerch(formValues)
      .then(results => {
        const products = JSON.parse(results?.data)?.products || []
        setResult(products)
      })
      .catch(err => {
        console.error(err)
        toast.error('Merchandising not found')
      })
      .finally(() => {
        setSearching(false)
        setSubmitting(false)
      })
  }

  const copyAllId = useCallback(() => {
    const allIds: string[] = result?.map(({ id }: { id: string }) => id) || []
    navigator.clipboard.writeText(allIds.join('\n'))
    toast.success('Copied')
  }, [result])

  const onFilterProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkList: string[] = event?.target?.value?.split(',').filter(Boolean) || []

    const resultProductIds: string[] = result?.map(({ id }: { id: string }) => id) || []
    const [existList, nonExistList] = filterProducts(checkList, resultProductIds)

    setFilterProductIds(checkList)
    setProductInexistent(nonExistList)
    setProductExistent(existList)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ marketingCode: '', merchCode: '', productIds: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => handleSubmit(values as MerchProductsSearchForm, formikBag)}
    >
      {() => {
        return (
          <Form>
            <Grid item container spacing={2} style={{ display: 'flex', alignItems: 'flex-start', minHeight: '80px' }}>
              <Grid item xs={3}>
                <FormControl className="fullWidth" variant="outlined" required={true}>
                  <InputLabel htmlFor="marketingCode" style={{ backgroundColor: 'white' }}>
                    Shop(s)
                  </InputLabel>
                  <Field
                    component={Select}
                    name="marketingCode"
                    inputProps={{ id: 'marketingCode' }}
                    disabled={searching}
                    variant="outlined"
                  >
                    {BRANDS.map(brand => (
                      <MenuItem key={brand.marketingCode} value={brand.marketingCode}>
                        {brand.marketingCode}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Field
                  required
                  name="merchCode"
                  label="MerchCode"
                  variant="outlined"
                  size="small"
                  className="fullWidth"
                  component={TextField}
                  disabled={searching}
                />
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Button disabled={searching} type="submit" color="primary" variant="contained">
                  {searching ? <CircularProgress size={50} /> : 'Search'}
                </Button>
              </Grid>
            </Grid>
            {searching ? (
              <CircularProgress size={50} />
            ) : (
              <>
                <Grid item container spacing={2}>
                  {result && (
                    <Grid item xs={12}>
                      Total: {result?.length} products
                    </Grid>
                  )}
                  {result && result?.length > 0 && (
                    <>
                      <Grid item xs={6}>
                        <MuiTextField
                          className="fullWidth"
                          label="Product ids"
                          variant="outlined"
                          onChange={onFilterProductChange}
                          helperText="Verify whether the product is available in the merchandising found"
                        />
                      </Grid>
                      {filterProductIds && filterProductIds?.length > 0 && (
                        <>
                          <Grid item xs={6}>
                            <Typography> Existent product ids: {productExistent?.join(',')}</Typography>
                            <Typography> Non-Existent product ids: {productInexistent?.join(',')}</Typography>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  <Grid item xs={12}>
                    {result && result?.length > 0 && (
                      <div style={{ height: '300px', overflowY: 'auto' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                Id
                                <IconButton color="primary" onClick={copyAllId}>
                                  <FileCopyIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {result.map((item, index) => (
                              <TableRow
                                key={index}
                                style={{
                                  backgroundColor: filterProductIds.includes(item.id) ? 'rgb(40, 167, 69, 0.75)' : '',
                                }}
                              >
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.name}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
export default CrmMerchProducts
