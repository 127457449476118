export interface User {
  id?: string
  uuid?: string
  firstName: string
  lastName: string
  email: string
  disabled: boolean
  password?: string
  emailVerified?: boolean
  groupId?: string
  otherAttributes?: any
  status?: UserStatus
  createdAt?: Date
  lastModified?: Date
  isAdmin?: boolean
}

export const ADMIN_GROUP_NAME = 'MARKETING_ADMIN'

export enum UserStatus {
  EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  CONFIRMED = 'CONFIRMED',
  RESET_REQUIRED = 'RESET_REQUIRED',
}

export const mappingCognitoUserToUser = (users: any) => {
  return users.map((user: any) => {
    const cloneUser = {} as any
    if (user?.Attributes?.length > 0) {
      user.Attributes.forEach((attr: { Name: string | number; Value: any }) => {
        cloneUser[attr.Name] = attr.Value
      })
    }
    return {
      uuid: user?.Username,
      status: user?.UserStatus,
      createdAt: new Date(user?.UserCreateDate),
      lastModified: new Date(user?.UserLastModifiedDate),
      ...(cloneUser?.name && { firstName: cloneUser?.given_name }),
      ...(cloneUser?.family_name && { lastName: cloneUser?.family_name }),
      ...(cloneUser?.sub && { groupId: cloneUser?.sub }),
      ...(cloneUser?.email && { email: cloneUser?.email }),
      ...(cloneUser?.email_verified && { emailVerified: cloneUser?.email_verified === 'true' ? true : false }),
    }
  })
}
