import { Grid, Paper, Box } from '@material-ui/core'
import { useState } from 'react'
import Np6SearchForm, { np6SearchFormValues } from './Np6SearchForm'
import Np6SearchTable from './Np6SearchTable'

const Np6Search = () => {
  const [np6sFormValues, setNp6sFormValues] = useState<np6SearchFormValues>()

  const onSubmit = async (values: np6SearchFormValues): Promise<void> => {
    setNp6sFormValues(values)
  }

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        <Paper>
          <Box p={2}>
            <Np6SearchForm onSubmit={onSubmit} />
          </Box>
        </Paper>
      </Grid>
      <Grid item style={{ maxWidth: '100%' }}>
        <Np6SearchTable searchValues={np6sFormValues} />
      </Grid>
    </Grid>
  )
}

export default Np6Search
