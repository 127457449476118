/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/style-prop-object */

import { useState, useContext, useCallback, useEffect, memo } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, Alert, Box, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { FormattedNumber, FormattedDate, FormattedTime } from 'react-intl'
import { toast } from 'react-toastify'
import {
  BatchStatus,
  Credit,
  CreditFormType,
  CreditType,
  DiscountType,
  FormMode,
  mapCreditsFromApi,
} from '../../model/credit'
import { GlobalProgressContext } from '../shared/GlobalProgress/GlobalProgressContext'
import { DataType, DatagridAlign, DataGridFilterType } from '../shared/Datagrid/DatagridModel'
import { SearchCriteria } from '../../model/SearchCriteria'
import { shop } from '../../model/shop'
import Datagrid from '../shared/Datagrid/Datagrid'
import { enum2options } from '../shared/Datagrid/helpers'
import CreditActions from './CreditActions'
import CreditForm from './CreditForm'
import ButtonGeneric from '../ButtonGeneric'
import { fetchShops } from '../../api/sanityClient'
import { groupBy } from 'lodash'
import { createCredit, searchCredit } from '../../api/creditClient'
import { Paper } from '@material-ui/core'
import { useAuth } from '../../providers/AuthContext'
import { calculateDuration } from '../../utils/helpers'

const verifyCreditSameType = (listCredit: Credit[], dateToCheck: Date = new Date()) => {
  if (listCredit.length === 0) {
    return [[], []]
  }

  const findDuplicates = (arr: string[]) =>
    arr && arr.length > 1 ? arr.filter((item, index) => arr.indexOf(item) !== index) : []
  const groupedCredit = groupBy(listCredit, 'type')
  const groupedCreditInscription =
    groupedCredit[CreditType.INSCRIPTION] && groupedCredit[CreditType.INSCRIPTION].length > 1
      ? groupedCredit[CreditType.INSCRIPTION]
          .filter(
            (c: Credit) =>
              new Date(c.startAt).getTime() <= new Date(dateToCheck).getTime() &&
              new Date(dateToCheck).getTime() < new Date(c.endAt).getTime()
          )
          .flatMap((c: Credit) => c.brandShopCodes)
      : []
  const groupedCreditTravelBack =
    groupedCredit[CreditType.TRAVEL_BACK] && groupedCredit[CreditType.TRAVEL_BACK].length > 1
      ? groupedCredit[CreditType.TRAVEL_BACK]
          .filter(
            (c: Credit) =>
              new Date(c.startAt).getTime() <= new Date(dateToCheck).getTime() &&
              new Date(dateToCheck).getTime() < new Date(c.endAt).getTime()
          )
          .flatMap((c: Credit) => c.brandShopCodes)
      : []
  return [[...new Set(findDuplicates(groupedCreditInscription))], [...new Set(findDuplicates(groupedCreditTravelBack))]]
}

const Credits = () => {
  const { promisesWithProgress } = useContext(GlobalProgressContext)
  const [creditList, setCreditList] = useState<Credit[]>([])
  const [duplicateCreditInscription, setDuplicateCreditInscription] = useState<string[]>([])
  const [duplicateCreditTravelBack, setDuplicateCreditTravelBack] = useState<string[]>([])

  const [shops, setShops] = useState<shop[]>([])

  useEffect(() => {
    fetchShops()
      .then(setShops)
      .catch(error => {
        console.error(error)
        toast.error('Failed to retrive shops, please see console for error')
      })
  }, [])

  const searchCredits = useCallback(
    async (c?: SearchCriteria) => {
      promisesWithProgress(
        searchCredit(c).then(res => {
          setCreditList(mapCreditsFromApi(res))
          const [duplicateCreditInscription, duplicateCreditTravelback] = verifyCreditSameType(
            mapCreditsFromApi(res),
            c?.filterBy?.available || new Date()
          )
          setDuplicateCreditInscription(duplicateCreditInscription)
          setDuplicateCreditTravelBack(duplicateCreditTravelback)
        })
      )
    },
    [promisesWithProgress]
  )

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        {(duplicateCreditInscription.length > 0 || duplicateCreditTravelBack.length > 0) && (
          <Box sx={{ marginBottom: '10px' }}>
            {duplicateCreditInscription.length > 0 && (
              <Alert severity="error">
                You have a duplicate on credit type {CreditType.INSCRIPTION}, brands:{' '}
                {duplicateCreditInscription.join(',')}
              </Alert>
            )}
            {duplicateCreditTravelBack.length > 0 && (
              <Alert severity="error">
                You have a duplicate on credit type {CreditType.TRAVEL_BACK.replaceAll('_', ' ')}, brands:{' '}
                {duplicateCreditTravelBack.join(',')}
              </Alert>
            )}
          </Box>
        )}

        <Grid container direction="row" justifyContent="end" alignItems="center" style={{ marginBottom: '20px' }}>
          <CreditCreation refreshAfterChange={searchCredits} />
        </Grid>

        <Paper elevation={0} style={{ padding: '16px' }}>
          <Datagrid<Credit>
            name="CREDITS"
            columns={[
              { label: 'Code', type: DataType.String, name: 'code', sortable: true },
              { label: 'Campaign start at', type: DataType.DateTime, name: 'startAt', sortable: true },
              { label: 'Campaign end at', type: DataType.DateTime, name: 'endAt', sortable: true },
              {
                label: 'Shop(s)',
                type: DataType.String,
                name: 'brandShopCodes',
                compute: (data: Credit) => data?.brandShopCodes?.join(','),
              },
              {
                label: 'Type',
                type: DataType.String,
                name: 'type',
                compute: (data: Credit) => data?.type?.replaceAll('_', ' '),
              },
              { label: 'Subtitle', type: DataType.String, name: 'label', sortable: true },
              { label: 'Description', type: DataType.String, name: 'description', sortable: true },
              {
                label: 'Discount Type',
                type: DataType.String,
                name: 'discountType',
              },
              {
                label: 'Amount',
                type: DataType.Custom,
                name: 'amount',
                align: DatagridAlign.right,
                sortable: true,
                compute: (data: Credit) =>
                  data?.discountType === DiscountType.PERCENT ? (
                    <FormattedNumber value={(data.amount as number) / 100} maximumFractionDigits={2} style="percent" />
                  ) : (
                    <FormattedNumber value={data.amount as number} style="currency" currency="EUR" />
                  ),
              },
              {
                label: 'Maxium amount',
                type: DataType.Currency,
                name: 'maxAmount',
                align: DatagridAlign.right,
              },
              { label: 'Validity period type', type: DataType.String, name: 'validityPeriodType' },
              { label: 'Validity start at', type: DataType.DateTime, name: 'validityStartAt' },
              { label: 'Validity end at', type: DataType.DateTime, name: 'validityEndAt' },
              { label: 'Validity Duration', type: DataType.Number, name: 'validityDuration' },
              { label: 'Duration type', type: DataType.String, name: 'validityDurationType' },
              { label: 'Credit existing members', type: DataType.Boolean, name: 'creditExistingMembers' },
              {
                label: 'Batch status',
                type: DataType.Actions,
                name: 'batch',
                customElement: CreditBatchStatus,
              },
              { label: 'Actions', type: DataType.Actions, name: 'actions', customElement: CreditActions },
            ]}
            filters={[
              {
                label: 'Ongoing',
                name: 'available',
                type: DataGridFilterType.Date,
              },
              {
                label: 'Type',
                name: 'type',
                options: enum2options(CreditType),
                type: DataGridFilterType.Select,
              },
              ...(shops && shops.length > 0
                ? [
                    {
                      label: 'Shops',
                      name: 'brandShopCodes',
                      options: shops?.map((s: shop) => ({ label: `${s.id} - ${s.name}`, value: s.id })),
                      type: DataGridFilterType.Select,
                      multiple: true,
                    },
                  ]
                : []),
            ]}
            data={creditList}
            fetch={searchCredits}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

// TODO: move inside Datagrid to be able using DatagridContext
const CreditCreation = memo(({ refreshAfterChange }: { refreshAfterChange?: Function }): JSX.Element => {
  const { authData } = useAuth()
  const { promisesWithProgress } = useContext(GlobalProgressContext)
  const [open, setOpen] = useState<boolean>(false)
  const handleSubmit = useCallback(
    async (data: CreditFormType) => {
      promisesWithProgress(
        createCredit(data)
          .then(res => {
            if (refreshAfterChange) {
              setTimeout(refreshAfterChange(), 300)
            }
            toast.success('Credit created successfully')
          })
          .catch(err => {
            toast.error('Failed to create credit, please check console for error')
          })
          .finally(() => setOpen(false))
      )
    },
    [promisesWithProgress, refreshAfterChange]
  )
  return (
    <>
      <ButtonGeneric
        label={'New credit'}
        isDisabled={!authData?.isAdmin}
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      />
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
          <CreditForm mode={FormMode.CREATE} handleSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </>
  )
})

const CreditBatchStatus = ({
  entity,
  refreshAfterChange,
}: {
  entity: Credit
  refreshAfterChange: (c?: SearchCriteria) => Promise<void>
}) => {
  const { startAt, endAt, status } = entity?.batch || {}
  if (!status) {
    return '-'
  }
  return status !== BatchStatus.INITIAL ? (
    <Tooltip
      title={
        <>
          {startAt && (
            <div>
              Start at: <FormattedDate value={startAt} /> {' - '}
              <FormattedTime value={startAt} />
            </div>
          )}
          {endAt && (
            <div>
              End at: <FormattedDate value={endAt} /> {' - '}
              <FormattedTime value={endAt} />
            </div>
          )}
          {startAt && endAt && <div>Duration: {calculateDuration(startAt, endAt)}</div>}
        </>
      }
      placement="top"
    >
      <div>{status?.toString().replaceAll('_', ' ')}</div>
    </Tooltip>
  ) : (
    status
  )
}

export default Credits
