import { useEffect, useState, useReducer, useCallback } from 'react'
import { ENDPOINTS, fetch } from '../api/backendClient'
import { PropertyReference, Sale } from '../model'
import { getBookmarkAttribute } from '../model/propertyReference'

type SalesTableModel = {
  _id: number
  shop: string
  rank: number
  propertyRefID: number
  propertyRefName: string
  merchCode: string
  startAt: string
  endAt: string
  resortName: string
  booking: number
  tripAdivisorNote: number
  competitive: number
  status: string
}

interface FormValues {
  hotelName?: string
  location?: string
  merchCode?: string
  shop?: string
  bookingDate?: Date
}

const formValuesReducer = (state: FormValues, action: FormValues): any => {
  return {
    ...state,
    ...action,
  }
}

const useGetSales = () => {
  const [sales, setSales] = useState<Sale[]>([])
  const [propertyReferences, setPropertyReferences] = useState<PropertyReference[]>([])
  const [salesTable, setSalesTable] = useState<SalesTableModel[]>([])
  const [loading, setLoading] = useState(true)
  const [formValues, setFormValues] = useReducer(formValuesReducer, {
    hotelName: undefined,
    location: undefined,
    merchCode: undefined,
    shop: undefined,
    bookingDate: undefined,
  })
  const fetchSales = useCallback(() => {
    setLoading(true)
    const query: Map<string, string> = new Map()
    if (formValues.hotelName) {
      query.set('propertyReferenceName.$containsIgnoreCase', formValues.hotelName)
    }
    if (formValues.merchCode) {
      query.set('merchCode.$containsIgnoreCase', formValues.merchCode)
    }
    if (formValues.location) {
      query.set('resortName.$containsIgnoreCase', formValues.location)
    }
    if (formValues.shop) {
      query.set('shops.$id', formValues.shop)
    }
    const fetchData = async () => {
      const result = await fetch<Sale[]>({
        endpoint: ENDPOINTS.sales.suffix,
        query: query,
      })
      setSales(result ?? [])
    }
    fetchData()
  }, [formValues.shop, formValues.hotelName, formValues.location, formValues.merchCode])

  useEffect(() => {
    fetchSales()
  }, [fetchSales])

  useEffect(() => {
    if (sales.length <= 0) {
      return
    }
    const fetchPropertyRef = async () => {
      const result = await fetch<PropertyReference[]>({
        endpoint: ENDPOINTS.propertyReferences.suffix,
        ids: sales.map(sale => sale.propertyReference.$id) ?? undefined,
      })
      setPropertyReferences(result)
    }
    fetchPropertyRef()
  }, [sales])

  useEffect(() => {
    setLoading(true)
    if (!sales || sales.length <= 0) {
      setLoading(false)
      setSalesTable([])
      return
    }
    const res = new Map<string, SalesTableModel[]>()
    sales.forEach(sale => {
      const propertyRef = propertyReferences.find(propertyRef => propertyRef._id === sale.propertyReference.$id)
      const booking = propertyRef ? getBookmarkAttribute(propertyRef, 'bookingComRating') : 0
      const tripAdivisorNote = propertyRef ? getBookmarkAttribute(propertyRef, 'tripAdvisorRating') : 0
      if (Array.isArray(sale.shops) && sale.shops.length > 0) {
        sale.shops.forEach(shop => {
          if (formValues.shop && shop.$id !== formValues.shop) {
            return
          }
          if (!res.has(shop.$id)) {
            res.set(shop.$id, [])
          }
          let status = 'Included'
          if (sale.shopConfig && sale.shopConfig[shop.$id]?.excluded) {
            status = 'Excluded'
          }
          res.get(shop.$id)?.push({
            _id: sale._id,
            rank: sale.rank,
            shop: shop.$id,
            propertyRefID: sale.propertyReference.$id,
            propertyRefName: sale.propertyReferenceName,
            merchCode: sale.merchCode,
            startAt: new Date(sale.startAt.$date).toLocaleDateString(),
            endAt: new Date(sale.endAt.$date).toLocaleDateString(),
            resortName: sale.resortName,
            booking: booking ?? 0,
            tripAdivisorNote: tripAdivisorNote ?? 0,
            competitive: propertyRef?.competitivityScore ?? 0,
            status: status,
          })
        })
      }
    })
    const table: SalesTableModel[] = []

    res.forEach(salesByShop => {
      table.push(...salesByShop)
    })
    table.sort((a, b) => a.shop.localeCompare(b.shop))
    setSalesTable(table)
    setLoading(false)
  }, [formValues.shop, propertyReferences, sales])

  return {
    sales: sales,
    salesTable: salesTable,
    allShops: [...new Set(salesTable?.map(sale => sale.shop))] ?? [],
    updateFormValue: setFormValues,
    getSalesLoading: loading,
    reload: () => fetchSales(),
  }
}

export type { SalesTableModel }

export default useGetSales
