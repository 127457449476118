import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@material-ui/core'
import { useState } from 'react'
import ReactJson from 'react-json-view'
import { toast } from 'react-toastify'
import { datalayer, DatalayerEvent } from '../../api/marketingClient'

const parseDatalayerItem = (item: any) => {
  const clone = { ...item }
  if (!clone.datalayer) {
    clone.datalayer = JSON.parse(item['DATALAYER'])
  }

  if (!clone.day) {
    clone.day = item['DAY']
  }
  return clone
}

interface Params {
  result?: DatalayerEvent[]
  setResult: (e: DatalayerEvent[] | undefined) => void
  onSelectConnectivity: (day: string, id: string) => void
}
export function DatalayerTable(params: Params) {
  const { result, setResult, onSelectConnectivity } = params
  const [selected, setSelected] = useState<DatalayerEvent>()
  if (!result) {
    return <></>
  }
  return (
    <Paper>
      <Box p={2}>
        {selected && (
          <Dialog open={selected !== undefined} onClose={() => setSelected(undefined)} maxWidth="xl">
            <DialogContent>
              <ReactJson displayDataTypes={false} src={selected} />
            </DialogContent>
          </Dialog>
        )}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {['Day', 'Brand', 'Event', 'PageTitle', 'Actions'].map((tableHeader, index) => (
                  <TableCell align="left" key={index}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(result) &&
                result.length > 0 &&
                result.map((item, index) => {
                  const parsedItem = parseDatalayerItem(item)
                  return (
                    <TableRow key={index}>
                      <TableCell>{parsedItem?.day}</TableCell>
                      <TableCell>
                        {parsedItem?.datalayer?.brand} {parsedItem?.datalayer?.shop}
                      </TableCell>
                      <TableCell>{parsedItem?.datalayer?.event}</TableCell>
                      <TableCell>{parsedItem?.datalayer?.page?.pageTitle}</TableCell>
                      <TableCell>
                        <Button onClick={() => setSelected(item)}>Details</Button>
                        {parsedItem?.datalayer?.quote?.connectivitySessionId && (
                          <Button
                            onClick={e => {
                              e.preventDefault()
                              onSelectConnectivity(
                                parsedItem?.day.substring(0, 10),
                                parsedItem?.datalayer?.quote?.connectivitySessionId
                              )
                            }}
                          >
                            Tourop
                          </Button>
                        )}
                        {parsedItem?.datalayer?.user?.user_biid && (
                          <Button
                            onClick={e => {
                              e.preventDefault()
                              setResult(undefined)
                              datalayer(parsedItem?.day.substring(0, 10), parsedItem?.datalayer?.user?.user_biid)
                                .then(setResult)
                                .catch(e => toast.error('' + e))
                            }}
                          >
                            User
                          </Button>
                        )}
                        {parsedItem?.datalayer?.user?.session_biid && (
                          <Button
                            onClick={e => {
                              e.preventDefault()
                              setResult(undefined)
                              datalayer(parsedItem?.day.substring(0, 10), parsedItem?.datalayer?.user?.session_biid)
                                .then(setResult)
                                .catch(e => toast.error('' + e))
                            }}
                          >
                            Session
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}
