import { Button, Grid, Paper, Box } from '@material-ui/core'
import Save from '@material-ui/icons/Save'
import { Field, Form, Formik } from 'formik'
import { Checkbox, TextField } from 'formik-material-ui'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { User } from '../../model/user'
import { getAuthenticatorApi } from '../../api/authenticatorClient'
import { useAuth } from '../../providers/AuthContext'
import { toast } from 'react-toastify'

type param = {
  id?: string
}

export default function UserContainer() {
  const [user, setUser] = React.useState<User>({
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    disabled: false,
  })
  const { id } = useParams<param>()
  const { authData } = useAuth()
  useEffect(() => {
    if (id && id !== 'new') {
      getAuthenticatorApi()
        .getUser(id)
        .then(setUser)
        .catch(e => toast.error(e))
    }
  }, [id, authData])

  return (
    <Paper>
      <Box p={2}>
        <Formik
          initialValues={user}
          enableReinitialize
          onSubmit={(e, { setSubmitting }) => {
            const api = getAuthenticatorApi()
            if (id && id !== 'new') {
              e.id = id
              api
                .updateUser(id, e)
                .then(setUser)
                .catch(e => toast.error(e))
                .finally(() => setSubmitting(false))
            } else {
              api
                .createUser(e)
                .then(setUser)
                .catch(e => alert(e))
                .finally(() => setSubmitting(false))
            }
          }}
        >
          <Form>
            <Grid container dir="column" spacing={3} justify="flex-start" alignItems="stretch">
              <Grid item xs={12} lg={3}>
                <Field component={TextField} name="email" label="Email" variant="outlined" size="small" fullWidth />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="otherAttributes.custom:marketingCode"
                  label="MarketingCode"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              {!(id && id !== 'new') && (
                <Grid item>
                  <Field
                    component={TextField}
                    name="password"
                    label="Temporary Password"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item>
                <Field
                  component={Checkbox}
                  name="disabled"
                  label="Disabled"
                  variant="outlined"
                  size="small"
                  fullWidth
                />{' '}
                Disabled
              </Grid>
              <Grid item>
                <Button startIcon={<Save />} type="submit" variant="contained" color="secondary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Box>
    </Paper>
  )
}
