import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteForever from '@material-ui/icons/DeleteForever'
import FileCopy from '@material-ui/icons/FileCopy'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { remove, search } from '../../api/promoCodesClient'
import { promocode } from '../../model/promocode'
import ButtonGeneric from '../ButtonGeneric'
import ConfirmDialog from '../ConfirmDialog'
import PromoCodeEditFormDialog from './PromoCodeEditFormDialog'
import { promoCodeSearchFormValues } from './PromoCodeSearchForm'
import { FormattedDate, FormattedTime } from 'react-intl'

const PromoCodeSearchTable = (props: { searchValues?: promoCodeSearchFormValues }) => {
  const [results, setResults] = useState<promocode[]>([])

  useEffect(() => {
    search(props?.searchValues || ({} as promoCodeSearchFormValues)).then(setResults)
  }, [props, props.searchValues])

  const useStyles = makeStyles({
    expired: {
      backgroundColor: '#eaeaea',
    },
    active: {
      backgroundColor: 'lightgoldenrodyellow',
    },
    future: {
      backgroundColor: 'lightsteelblue',
    },
  })

  const tableHeader = (
    <TableHead>
      <TableRow>
        {['Code', 'Shop', 'Label', 'Discount', 'From', 'To'].map((tableHeader, index) => (
          <TableCell align="left" key={index}>
            {tableHeader}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const classes = useStyles()

  const tableBody = (
    <TableBody>
      {results?.map((result, index) => (
        <TableRow
          key={result.id}
          className={
            result.bookingEndDate && new Date(result.bookingEndDate) < new Date()
              ? classes.expired
              : result.bookingStartDate && new Date(result.bookingStartDate) > new Date()
              ? classes.future
              : classes.active
          }
        >
          <TableCell>
            <Link to={`/promocode/${result.id}`}>{result.code}</Link>
          </TableCell>
          <TableCell align="left">{result.brandShop}</TableCell>
          <TableCell align="left">{result.label}</TableCell>
          <TableCell align="right">
            {result.discount} {result.discountType === 'Percentage' ? '%' : result.currency}
          </TableCell>
          <TableCell align="left">
            {result?.bookingStartDate ? (
              <>
                <FormattedDate value={result.bookingStartDate} /> - <FormattedTime value={result.bookingStartDate} />
              </>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell align="left">
            {result?.bookingEndDate ? (
              <>
                <FormattedDate value={result.bookingEndDate} /> - <FormattedTime value={result.bookingEndDate} />
              </>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell align="left">
            <ButtonGroup>
              <DuplicatePromoCodeButton promocodeValue={result} />
              <DeletePromoCodeButton
                promocodeValue={result}
                onSuccess={() => setResults(results.filter(m => m.id !== result.id))}
              />
            </ButtonGroup>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
  return (
    <Paper>
      <Box p={2}>
        <TableContainer>
          <Table size="small">
            {tableHeader}
            {tableBody}
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}

const DeletePromoCodeButton = (props: { promocodeValue: promocode; onSuccess?: () => void }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  return (
    <div>
      <ButtonGeneric startIcon={<DeleteForever />} size="small" isDanger={true} onClick={() => setIsDialogOpen(true)} />
      <ConfirmDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        onConfirm={() =>
          remove(props.promocodeValue?.id)
            .then(() => {
              toast.success('PromoCode deleted')
              if (props.onSuccess) props.onSuccess()
            })
            .catch(error => {
              console.error(error)
              toast.error(error)
            })
        }
        isDanger={true}
        title={'Delete PromoCode ?'}
        content={`Delete promocode ${props.promocodeValue?.code} (${props.promocodeValue?.id})`}
      />
    </div>
  )
}

const DuplicatePromoCodeButton = (props: { promocodeValue: promocode }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  return (
    <div>
      <ButtonGeneric startIcon={<FileCopy />} size="small" onClick={() => setIsDialogOpen(true)} />
      <PromoCodeEditFormDialog
        open={isDialogOpen}
        promoCodeValue={{ ...props.promocodeValue, id: '' }}
        handleClose={() => setIsDialogOpen(false)}
        onSuccess={() => toast.success('PromoCode created')}
        onFail={() => toast.error('Error during promocode creation, please see console for error')}
      />
    </div>
  )
}

export default PromoCodeSearchTable
