import { memo, useCallback, useContext, useState } from 'react'
import { Menu, MenuItem, Checkbox, IconButton, TableRow, TableCell, Box } from '@mui/material'
import { DatagridColumn, DatagridContext, DataGridFilter, DataGridFilterType } from './DatagridModel'
import ClearIcon from '@mui/icons-material/Clear'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { isEmpty } from 'lodash'
import { DatagridFilterSelect, DatagridFilterDate } from './Filters'

const DatagridToolbar = <T,>() => {
  const { columns, filters, isColumnVisible, onToggleColumnVisibility, searchCriteria, updateSearchCriteria } =
    useContext(DatagridContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const handleClearFilter = useCallback(() => {
    const newSearchCriteria = { ...searchCriteria }
    delete newSearchCriteria.filterBy
    updateSearchCriteria(newSearchCriteria)
  }, [searchCriteria, updateSearchCriteria])

  const filterRender = useCallback((filter: DataGridFilter) => {
    switch (filter.type) {
      case DataGridFilterType.Select:
        return <DatagridFilterSelect name={filter.name} />
      case DataGridFilterType.Date:
        return <DatagridFilterDate name={filter.name} />
      default:
        return ''
    }
  }, [])

  return (
    <TableRow>
      <TableCell colSpan={100} align="right">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {filters && filters.length > 0 && (
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {filters.map((filter: DataGridFilter) => filterRender(filter))}
              {!isEmpty(searchCriteria?.filterBy) && (
                <IconButton color="primary" aria-label="Clear filter" onClick={handleClearFilter}>
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          )}
          <Box>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <RemoveRedEyeIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {columns.map(({ name, label }: DatagridColumn<T>) => (
                <MenuItem key={`column-visibility-${name}`} onClick={() => onToggleColumnVisibility(name)}>
                  <Checkbox checked={isColumnVisible(name)} />
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default memo(DatagridToolbar)
