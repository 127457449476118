import { Dialog, DialogContent, Grid, Paper, Box } from '@material-ui/core'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { connectivity, ConnectivityEvent, datalayer, DatalayerEvent } from '../../api/marketingClient'
import { ConnectivityResultTable } from './connectivitytable'
import { DatalayerForm, formValues } from './datalayerform'
import { DatalayerTable } from './datalayertable'

export const DatalayerContainer = () => {
  const [result, setResult] = useState<DatalayerEvent[]>()
  const [connectivityResult, setConnectivityResult] = useState<ConnectivityEvent[]>()

  const onSubmit = async (values: formValues): Promise<void> => {
    const result = await datalayer(values.date, values.filter)
    setResult(result)
  }

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        <Paper>
          <Box p={2}>
            <DatalayerForm onSubmit={onSubmit} />
          </Box>
        </Paper>
      </Grid>
      <Grid item style={{ maxWidth: '100%' }}>
        <Dialog open={connectivityResult !== undefined} onClose={() => setConnectivityResult(undefined)} maxWidth="xl">
          <DialogContent>
            <ConnectivityResultTable result={connectivityResult} />
          </DialogContent>
        </Dialog>
        <DatalayerTable
          result={result}
          setResult={setResult}
          onSelectConnectivity={(day, id) => {
            setConnectivityResult(undefined)
            connectivity(day, id)
              .then(setConnectivityResult)
              .catch(e => toast.error('' + e))
          }}
        />
      </Grid>
    </Grid>
  )
}
