import { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core'
import { Select, TextField } from 'formik-material-ui'
import ButtonSearch from '../../components/ButtonSearch'
import RotateLeft from '@material-ui/icons/RotateLeft'
import ButtonGeneric from '../../components/ButtonGeneric'

type Props = {
  onSubmit: (value: SearchValues) => void
  shops?: string[]
  disabled?: boolean
}

type SearchValues = {
  hotelName: string
  location: string
  merchCode: string
  topic: string
  shop: string
  bookingDate: Date
}

const Search = (props: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { onSubmit, shops, disabled } = props
  const initialValues: SearchValues = {
    hotelName: '',
    location: '',
    merchCode: '',
    topic: '',
    shop: '',
    bookingDate: new Date(),
  }
  const handleReset = () => {
    onSubmit(initialValues)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setIsSubmitting(true)
        setSubmitting(true)
        onSubmit(values)
        setSubmitting(false)
        setIsSubmitting(false)
      }}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={3}>
                <Field
                  name="hotelName"
                  label="Hotel Name"
                  variant="outlined"
                  size="small"
                  className="fullWidth"
                  disabled={disabled}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="location"
                  label="Location"
                  variant="outlined"
                  size="small"
                  className="fullWidth"
                  disabled={disabled}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="merchCode"
                  label="Merch Code"
                  variant="outlined"
                  size="small"
                  className="fullWidth"
                  disabled={disabled}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl className="formik-select--outlined fullWidth" size="small" variant="outlined">
                  <InputLabel htmlFor="shop">Shop</InputLabel>
                  <Field
                    disabled={disabled}
                    component={Select}
                    name="shop"
                    variant="outlined"
                    inputProps={{ id: 'shop' }}
                  >
                    <MenuItem value={''}>None</MenuItem>
                    {shops?.map(shop => (
                      <MenuItem key={shop} value={shop}>
                        {shop}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={'flex-end'}>
              <Grid item>
                <ButtonGeneric
                  isDisabled={isSubmitting || disabled}
                  label={'Reset'}
                  startIcon={<RotateLeft />}
                  color={'secondary'}
                  onClick={() => {
                    resetForm()
                    handleReset()
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonSearch isDisabled={isSubmitting || disabled} />
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Search
