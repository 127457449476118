import { useContext, useCallback } from 'react'
import { MenuItem, FormControl, InputLabel, Select, SelectChangeEvent, Box } from '@mui/material'
import { DatagridContext, DataGridFilterOption } from '../DatagridModel'

const DatagridFilterSelect = ({ name }: { name: string }) => {
  const { filters, searchCriteria, updateSearchCriteria } = useContext(DatagridContext)
  const filter = filters?.find(f => f.name === name)
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      if (event.target.value) {
        updateSearchCriteria({
          ...searchCriteria,
          filterBy: {
            ...searchCriteria.filterBy,
            [name]: event?.target?.value,
          },
        })
      }
    },
    [name, searchCriteria, updateSearchCriteria]
  )

  const handleChangeMultiple = (event: SelectChangeEvent) => {
    updateSearchCriteria({
      ...searchCriteria,
      filterBy: {
        ...searchCriteria.filterBy,
        [name]: typeof event?.target?.value === 'string' ? event?.target?.value.split(',') : event?.target?.value,
      },
    })
  }

  return filter && filter.options ? (
    <Box sx={{ width: 250 }}>
      <FormControl fullWidth>
        <InputLabel id={`filter-${filter.name}`}>{filter.label}</InputLabel>
        <Select
          labelId={`filter-${filter.name}`}
          id={`filter-${filter.name}`}
          value={searchCriteria?.filterBy?.[name] || (filter.multiple ? [] : '')}
          label={filter.label}
          onChange={filter.multiple ? handleChangeMultiple : handleChange}
          multiple={filter.multiple || false}
        >
          {filter?.options?.map((o: DataGridFilterOption) => (
            <MenuItem key={`filter-${filter.name}-option-${o.value}`} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : (
    <></>
  )
}

export default DatagridFilterSelect
