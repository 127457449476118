import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getTableData } from '../../api/np6Client'
import { np6SearchFormValues } from './Np6SearchForm'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { toast } from 'react-toastify'

const Np6SearchTable = (props: { searchValues?: np6SearchFormValues }) => {
  const [results, setResults] = useState<any[]>([])
  const [headers, setHeaders] = useState<string[]>([])

  useEffect(() => {
    if (props.searchValues?.account && props.searchValues?.tableId) {
      getTableData(props.searchValues.account, props.searchValues.tableId).then(results => {
        var header = new Set<string>()
        results.forEach(a => {
          for (const b in a) {
            header.add(b)
          }
        })
        setHeaders([...header])
        setResults(results)
      })
    }
  }, [props, props.searchValues])

  const cellRender = (data: any) => {
    let dataType
    dataType = typeof data
    if (dataType === 'string' && data.includes('res.cloudinary.com')) {
      dataType = 'image'
    }
    switch (dataType) {
      case 'boolean':
        return data ? 'true' : 'false'
      case 'image':
        return data ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '120px' }}>
            <Tooltip title={data} placement="bottom">
              <img src={data} alt={data} style={{ width: '100px', height: 'auto' }} />
            </Tooltip>
            <IconButton
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(data)
                toast.success('Copied')
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </div>
        ) : (
          '-'
        )
      default:
        return data
    }
  }

  const tableHeader = (
    <TableHead>
      <TableRow>
        {headers.map((tableHeader, index) => (
          <TableCell align="left" key={index}>
            {tableHeader}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const tableBody = (
    <TableBody>
      {results?.map((result, index) => (
        <TableRow key={result.id}>
          {headers.map((tableHeader, index) => (
            <TableCell align="left">{cellRender(result[tableHeader])}</TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )

  return results && results?.length > 0 ? (
    <Paper>
      <Box component="div" p={2}>
        <TableContainer style={{ overflow: 'auto', maxHeight: '500px' }}>
          <Table size="small" stickyHeader>
            {tableHeader}
            {tableBody}
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  ) : (
    <></>
  )
}

export default Np6SearchTable
