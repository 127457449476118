import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CrmMerchProducts from './CrmMerchProducts'
import CrmProducts from './CrmProducts'
import StressMarketing from './StressMarketing'

const Crm = () => {
  return (
    <>
      <Accordion elevation={0} variant="outlined" defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">Search products with merch code</Typography>
        </AccordionSummary>
        <div style={{ padding: '16px' }}>
          <CrmMerchProducts />
        </div>
      </Accordion>
      <Accordion elevation={0} variant="outlined" defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="subtitle1">Search product with end date</Typography>
        </AccordionSummary>
        <div style={{ padding: '16px' }}>
          <CrmProducts />
        </div>
      </Accordion>
      <Accordion elevation={0} variant="outlined" defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="subtitle1">Force stress marketing update</Typography>
        </AccordionSummary>
        <div style={{ padding: '16px' }}>
          <StressMarketing />
        </div>
      </Accordion>
    </>
  )
}

export default Crm
