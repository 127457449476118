import { createContext, ReactNode } from 'react'
import { SearchCriteria, SearchOrderBy } from '../../../model/SearchCriteria'

export enum DataType {
  Number = 'number',
  String = 'string',
  Date = 'date',
  Time = 'time',
  DateTime = 'datetime',
  Currency = 'currency',
  Percentage = 'percentage',
  Custom = 'custom',
  Boolean = 'boolean',
  Actions = 'actions',
}
export type DatagridColumnType = DataType
export type DatagridPrefixPosition = 'before' | 'after'
export enum DatagridAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}
export type DatagridColumn<T> = {
  label: string
  name: string
  type: DatagridColumnType
  align?: DatagridAlign
  prefix?: string
  prefixPosition?: DatagridPrefixPosition
  sortable?: boolean
  compute?: (data: T) => any
  visibility?: boolean
  customElement?: any
}
export type DataGridFilterOption = {
  label: string
  value: string
}
export enum DataGridFilterType {
  Select = 'select',
  Date = 'date',
  Custom = 'custom',
  Checkbox = 'checkbox',
}
export type DataGridFilter = {
  label: string
  name: string
  multiple?: boolean
  options?: Array<DataGridFilterOption>
  type: DataGridFilterType
}
export type DatagridProps<T> = {
  name: string
  columns: Array<DatagridColumn<T>>
  data: Array<T> | []
  criteriaDefault?: SearchCriteria
  defaultHiddenColumns?: string[]
  fetch?: (c?: SearchCriteria) => Promise<void>
  filters?: Array<DataGridFilter>
  actionsMutilple?: ReactNode
}
export type DatagirdContext<T> = {
  searchCriteria: SearchCriteria
  updateSearchCriteria: Function
  selected: number[]
  onSelect: (indexes: number[]) => void
  hiddenColumns: string[]
  onToggleColumnVisibility: (name: string) => void
  isColumnVisible: (name: string) => boolean
  onSort: (name: string, order: SearchOrderBy) => void
} & Pick<DatagridProps<T>, 'columns' | 'filters'>

const defaultDatagridContext = {
  searchCriteria: {},
  updateSearchCriteria: () => {},
  selected: [],
  onSelect: (indexes: number[]) => {},
  hiddenColumns: [],
  onToggleColumnVisibility: (name: string) => {},
  isColumnVisible: (name: string) => false,
  onSort: (name: string, order: SearchOrderBy) => {},
  columns: [],
  filters: [],
}
export const DatagridContext = createContext<DatagirdContext<any>>(defaultDatagridContext)
