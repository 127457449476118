import { useState } from 'react'
import { CircularProgress, Grid, Paper, Box } from '@material-ui/core'
import { useGetSales, usePatchSales, useSetSelectedItems } from '../../hooks'
import Table from './table'
import Search from './search'
import Actions from './actions'
import { Sale } from '../../model'
import { toast } from 'react-toastify'

const Filter = () => {
  const { sales, salesTable, allShops, updateFormValue, getSalesLoading, reload } = useGetSales()
  const { patchOneSale } = usePatchSales()
  const { selectedItems, handleSelect, handleSelectAll, isItemSelected, resetSelectedItems } = useSetSelectedItems()
  const [patching, setPatching] = useState(false)
  const loading = patching || getSalesLoading

  const handleClick = async (action: string) => {
    if (selectedItems.length <= 0) {
      toast.error('no sale(s) selected')
      return
    }
    setPatching(true)
    const updatedSales: Sale[] = []
    selectedItems?.forEach(item => {
      const excluded = action === 'exclude'
      const saleInfo = item.index.split('-')
      const id = saleInfo[0]
      const shop = saleInfo[1]
      const oldSale = sales?.find(sale => sale._id === parseInt(id))
      const updatedSaleIdx = updatedSales.findIndex(sale => sale._id === parseInt(id))

      if (!oldSale) {
        return
      }
      if (updatedSaleIdx !== -1) {
        updatedSales[updatedSaleIdx].shopConfig[shop] = { excluded: excluded }
      } else {
        updatedSales.push({ ...oldSale, shopConfig: { ...oldSale.shopConfig, [shop]: { excluded: excluded } } })
      }
    })
    // forEach loop does not support async callbacks
    for (let i = 0; i < updatedSales.length; i++) {
      const oldSale = sales?.find(sale => sale._id === updatedSales[i]._id)

      if (!oldSale) {
        return
      }
      await patchOneSale(oldSale, updatedSales[i])
    }
    toast.success(updatedSales.length > 1 ? 'Sales updated' : 'Sale updated')
    setPatching(false)
    resetSelectedItems()
    reload()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Search
              shops={allShops}
              onSubmit={values => {
                updateFormValue({
                  ...values,
                })
                resetSelectedItems()
              }}
              disabled={loading}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Box mb={4}>
              <Actions disabled={loading} handleClick={handleClick} />
            </Box>
            {loading ? (
              <CircularProgress size={50} />
            ) : (
              <Table
                salesTable={salesTable}
                handleSelect={handleSelect}
                handleSelectAll={handleSelectAll}
                isSelected={isItemSelected}
                resetSelectedItems={resetSelectedItems}
              />
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Filter
