export enum DiscountType {
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
}
export enum CreditValidityPeriodType {
  FIXED_DATES = 'FIXED_DATES',
  DURATION = 'DURATION',
}
export enum CreditType {
  INSCRIPTION = 'INSCRIPTION',
  LOYALTY = 'LOYALTY',
  TRAVEL_BACK = 'TRAVEL_BACK',
}
export enum ValidityDurationType {
  DAY = 'DAYS',
  WEEK = 'WEEKS',
  MONTH = 'MONTHS',
  YEAR = 'YEARS',
}
export enum BatchStatus {
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  INITIAL = 'INITIAL',
}

export type Batch = {
  startAt?: Date
  endAt?: Date
  status: BatchStatus
}

export type Credit = {
  _id: string
  startAt: Date
  endAt: Date
  code?: string
  type: CreditType
  label?: string
  description: string
  discountType: DiscountType
  amount: number
  maxAmount: number
  validityPeriodType: CreditValidityPeriodType
  validityStartAt: Date
  validityEndAt: Date
  validityDuration: number
  validityDurationType: ValidityDurationType
  brandShopCodes: Array<string>
  creditExistingMembers?: boolean
  batch?: Batch
}
export type CreditFormType = Partial<Credit> | Credit

export const mapCreditFromApi = (apiCredit: any): Credit => ({
  ...apiCredit,
  _id: apiCredit._id.toString(),
})

export const mapCreditsFromApi = (apiCredits: any): Credit[] => apiCredits.map(mapCreditFromApi)

export enum FormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  CLONE = 'CLONE',
}
