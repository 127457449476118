import { AutocompleteRenderInputParams } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { ErrorMessage } from 'formik'
import { TextField as MaterialTextField } from '@material-ui/core'

export function FormAutocompleteField(props: any) {
  return (
    <>
      <Autocomplete
        size="small"
        filterSelectedOptions
        renderInput={(params: AutocompleteRenderInputParams) => (
          <MaterialTextField
            {...params}
            required={props.required}
            name={props.name}
            label={props.label}
            value={props.value}
            variant="filled"
            size="small"
            fullWidth
          />
        )}
        {...props}
      />
      <ErrorMessage className={'errorMessage'} component="p" name={props.name} />
    </>
  )
}
