import { Checkbox, TableBody, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { stage } from '../../config/config'
import { SalesTableModel } from '../../hooks'
import { ListLayout } from '../../layouts'

type Props = {
  salesTable?: SalesTableModel[]
  handleSelect?: (index: string) => void
  handleSelectAll?: (indexes: string[]) => void
  isSelected: (index: string) => boolean
  resetSelectedItems: () => void
}

type ListWrapperProps = {
  handleSelectAllClick: (checked: boolean) => void
  children: JSX.Element
}

const headers = [
  'Rank',
  'Sales ID',
  'Property',
  'Merch code',
  'Place',
  'Shop',
  'Start at',
  'End at',
  'Booking',
  'Trip note',
  'Competitive',
  'Status',
  'Images',
]

const TableWrapper = (props: ListWrapperProps) => {
  const { handleSelectAllClick, children } = props
  return (
    <ListLayout headers={headers} withCheckbox={true} handleSelectAllClick={handleSelectAllClick}>
      {children}
    </ListLayout>
  )
}

const Table = (props: Props) => {
  const { salesTable, handleSelect, handleSelectAll, isSelected, resetSelectedItems } = props

  const handleClick = (id: number, shop: string) => {
    if (handleSelect) {
      handleSelect(buildIndex(id, shop))
    }
  }

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const selectedItems = salesTable?.map(sale => `${sale._id}-${sale.shop}`)

      if (handleSelectAll && selectedItems) {
        handleSelectAll(selectedItems)
      }
      return
    }
    resetSelectedItems()
  }
  if (salesTable && salesTable?.length <= 0) {
    return <div>No Sales to display</div>
  }

  const buildIndex = (id: number, shop: string) => `${id}-${shop}`

  if (salesTable && salesTable.length > 0) {
    const table = (
      <TableBody>
        {salesTable.map((sale, index) => {
          const isItemSelected = isSelected(buildIndex(sale._id, sale.shop))

          return (
            <TableRow
              hover
              aria-checked={isItemSelected}
              selected={isItemSelected}
              key={index}
              onClick={() => handleClick(sale._id, sale.shop)}
            >
              <TableCell padding={'checkbox'}>
                <Checkbox checked={isItemSelected} />
              </TableCell>
              <TableCell>{sale.rank}</TableCell>
              <TableCell align={'right'}>{sale._id}</TableCell>
              <TableCell align={'right'}>
                {sale.propertyRefName} ({sale.propertyRefID})
              </TableCell>
              <TableCell align={'right'}>{sale.merchCode}</TableCell>
              <TableCell align={'right'}>{sale.resortName}</TableCell>
              <TableCell align={'right'}>{sale.shop}</TableCell>
              <TableCell align={'right'}>{sale.startAt}</TableCell>
              <TableCell align={'right'}>{sale.endAt}</TableCell>
              <TableCell align={'right'}>{sale.booking}</TableCell>
              <TableCell align={'right'}>{sale.tripAdivisorNote}</TableCell>
              <TableCell align={'right'}>{sale.competitive?.toFixed(1)}</TableCell>
              <TableCell align={'right'}>{sale.status}</TableCell>
              <TableCell align={'right'}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://touroperating.${stage}.perfectstay.io/images/display/property/${sale.propertyRefID}`}
                >
                  Link
                </a>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )

    return <TableWrapper handleSelectAllClick={handleSelectAllClick}>{table}</TableWrapper>
  }
  return <div />
}

export default Table
