import { PropsWithChildren } from 'react'
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
} from '@material-ui/core'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MailIcon from '@material-ui/icons/Mail'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import EuroIcon from '@material-ui/icons/Euro'
import LayersIcon from '@material-ui/icons/Layers'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import FilterListIcon from '@material-ui/icons/FilterList'
import WebIcon from '@material-ui/icons/Web'
import SatelliteIcon from '@material-ui/icons/Satellite'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { landingPageURL, pushNotificationURL } from '../api/sanityClient'
import config from '../config/config'
import { isLocal, stage } from '../config/config'
import { useAuth } from '../providers/AuthContext'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuItem: {
      color: '#2b383f',
      textDecoration: 'none !important',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
)

const sideBarItems = [
  { label: 'Merchandising', path: '/merch', icon: <SatelliteIcon />, external: false },
  { label: 'PromoCodes', path: '/promocode', icon: <ReceiptIcon />, external: false },
  { label: 'Partners Login', path: '/users', icon: <VpnKeyIcon />, external: false },
  { label: 'NP6', path: '/np6', icon: <MailIcon />, external: false },
  { label: 'Filter', path: '/filter', icon: <FilterListIcon />, external: false },
  { label: 'Credits', path: '/credits', icon: <EuroIcon />, external: false },
  { label: 'CRM', path: '/crm', icon: <WebIcon />, external: false },
  { label: 'Datalayer', path: '/datalayer', icon: <LayersIcon />, external: false },
  { label: 'Roles', path: '/roles', icon: <AssignmentIndIcon />, external: false },
  { label: 'Push notification', path: pushNotificationURL, icon: <NotificationsActiveIcon />, external: true },
  { label: 'Landing', path: landingPageURL, icon: <ViewAgendaIcon />, external: true },
  { label: 'Ranking', path: config.airtableRankingURL, icon: <TurnedInIcon />, external: true },
]

const environment = () => {
  if (stage === 'live') {
    return <></>
  }
  // Making the current environement more obvious to avoid possible live issues 🔥💻🔥
  return (
    <span className="header-element env-indicator">
      <span>
        env:{' '}
        <b className="lowercase">
          {stage}
          {isLocal ? ' (local)' : ''}
        </b>
      </span>
    </span>
  )
}

const DashboardLayout = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles()
  const theme = useTheme()
  const { authData, logout } = useAuth()
  const [open, setOpen] = React.useState(false)
  const location = useLocation()
  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    setUserEmail(authData.userEmail || 'unauthenticated')
  }, [authData])

  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {sideBarItems.find(item => item.path === location?.pathname)?.label || 'Marketing services'}
          </Typography>
          <Typography variant="body1" style={{ marginLeft: 'auto' }}>
            {environment()} | {userEmail}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {sideBarItems
            .filter(item => !item.external)
            // eslint-disable-next-line array-callback-return
            .map((item, index) => {
              if ((item.path === '/roles' && authData.isAdmin) || item.path !== '/roles') {
                return (
                  <RouterLink to={item.path} className={classes.menuItem}>
                    <Tooltip title={item.label} placement="right">
                      <ListItem button key={index} selected={location?.pathname === item.path}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItem>
                    </Tooltip>
                  </RouterLink>
                )
              }
            })}
        </List>
        <Divider />
        <List>
          {sideBarItems
            .filter(item => item.external)
            .map((item, index) => (
              <Link href={item.path} target="_blank" className={classes.menuItem}>
                <Tooltip title={item.label} placement="right">
                  <ListItem button key={index}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                </Tooltip>
              </Link>
            ))}
        </List>
        <List style={{ marginTop: 'auto' }}>
          <Tooltip title="Logout" placement="right">
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export default DashboardLayout
