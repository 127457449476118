import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
  KeyboardDateTimePickerProps as MuiKeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { FieldProps, getIn } from 'formik'
import React from 'react'

interface KeyboardDateTimePickerProps
  extends FieldProps,
    Omit<MuiKeyboardDateTimePickerProps, 'name' | 'value' | 'error' | 'onChange'> {}

function fieldToKeyboardDateTimePicker({
  disabled,
  field,
  form: { isSubmitting, touched, errors, setFieldValue, setFieldError, setFieldTouched },
  ...props
}: KeyboardDateTimePickerProps): MuiKeyboardDateTimePickerProps {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError
  const { value, ...fields2 } = field
  return {
    value: value || null,
    ...props,
    ...fields2,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled ? disabled : isSubmitting,
    onChange: (picker, value) => {
      setFieldValue(field.name, !!picker ? picker : field.value)
      setFieldTouched(field.name, true, false)
    },
    onBlur: (event: any) => {
      setFieldTouched(field.name, true, false)
    },
    ampm: false,
  }
}
export const KeyboardDateTimePicker = ({ children, ...props }: KeyboardDateTimePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiKeyboardDateTimePicker {...fieldToKeyboardDateTimePicker(props)}>{children}</MuiKeyboardDateTimePicker>
    </MuiPickersUtilsProvider>
  )
}
